import { Score, useScoreSummaries, useScores } from '@capturi/api-scoring'
import { useFilterPeriodContext } from '@capturi/filters'
import { DefaultFallbackComponent } from '@capturi/react-utils'
import { Spinner } from '@capturi/ui-components'
import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

import { routes as scoringRoutes } from '../../../../pages/ScoringPage'
import { Pagination } from '../../components/Pagination'
import ScoreListItem from './ScoreListItem'

export type ScoreData = Score & {
  averageScore: string
  conversationsTotal: number
}

const variants = {
  enter: (direction: string) => {
    return {
      x: direction === 'right' ? 1536 : -1536,
      zIndex: 1,
      opacity: 0,
      width: '100%',
    }
  },
  center: {
    x: 0,
    zIndex: 1,
    opacity: 1,
    width: '100%',
  },
  exit: (direction: string) => {
    return {
      x: direction === 'left' ? 1536 : -1536,
      opacity: 0,
      zIndex: 1,
      width: '100%',
    }
  },
}

const itemsPerPage = 3
const summaryAvgFormat = { maximumFractionDigits: 1, minimumFractionDigits: 0 }

const ScoreList: React.FC = () => {
  const navigate = useNavigate()
  const { data, isLoading, error: scoreError } = useScores()
  const { period } = useFilterPeriodContext()
  const { data: summaries, error: summaryError } = useScoreSummaries(period)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [direction, setDirection] = useState<string>('right')
  const hasError = scoreError || summaryError
  const summariesMap = new Map(
    summaries?.map((summary) => [summary.uid, summary]),
  )

  const handleNavigateToScore = useCallback(
    (scoreUid: string) => {
      navigate(scoringRoutes.main(scoreUid))
    },
    [navigate],
  )

  const scoresWithSummaries = useMemo(() => {
    return data?.reduce<ScoreData[]>((acc, score) => {
      const matchingScore = summariesMap.get(score.uid)

      if (matchingScore) {
        acc.push({
          ...score,
          averageScore: i18n.number(
            matchingScore.averageScore,
            summaryAvgFormat,
          ),
          conversationsTotal: matchingScore.conversationsTotal,
        })
      }
      return acc
    }, [])
  }, [data, summariesMap])

  const scoresToShow = useMemo(
    () =>
      scoresWithSummaries?.slice(
        currentPage * itemsPerPage,
        currentPage * itemsPerPage + itemsPerPage,
      ),
    [scoresWithSummaries, currentPage],
  )

  if (hasError) return <DefaultFallbackComponent error={hasError} />
  if (isLoading) {
    return (
      <HStack justify="center" w="100%" p={3}>
        <Spinner color="primary.500" />
      </HStack>
    )
  }

  if (!scoresToShow) {
    return (
      <HStack justify="center" w="100%" p={3}>
        <Text fontSize="sm" fontWeight="medium" mb={2}>
          <Trans>No scores to display. Try again.</Trans>
        </Text>
      </HStack>
    )
  }

  const totalPages = Math.ceil(
    (scoresWithSummaries?.length || 0) / itemsPerPage,
  )

  const handlePageChange = (pageIndex: number): void => {
    setDirection(pageIndex > currentPage ? 'right' : 'left')
    setCurrentPage(pageIndex)
  }

  return (
    <VStack alignItems="end">
      <Flex w="100%" gap="4">
        <AnimatePresence initial={false} mode="popLayout" custom={direction}>
          {scoresToShow?.map((score) => (
            <Flex flex="0 0 calc( 33.3333% - 10.7px)" w="20px" key={score.uid}>
              <motion.div
                key={currentPage}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  ease: 'easeInOut',
                  duration: 0.7,
                }}
              >
                <HStack w="100%" key={score.uid} gap={0}>
                  <ScoreListItem
                    score={score}
                    onScoreClick={handleNavigateToScore}
                  />
                </HStack>
              </motion.div>
            </Flex>
          ))}
        </AnimatePresence>
      </Flex>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </VStack>
  )
}

export default ScoreList
