import { ErrorBoundary } from '@capturi/react-utils'
import { Spinner } from '@capturi/ui-components'
import React from 'react'
import { Route, Routes } from 'react-router'

export { default as routes } from './routes'

const LibraryPage = React.lazy(() => import('./LibraryPage'))

const LibraryRouter: React.FC = () => {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<Spinner display="block" m="5rem auto" />}>
        <Routes>
          <Route path="/*" element={<LibraryPage />} />
        </Routes>
      </React.Suspense>
    </ErrorBoundary>
  )
}

export default LibraryRouter
