import {
  ConversationResponseModel,
  useWordSearch,
} from '@capturi/api-conversations'
import React from 'react'
import { useDeepCompareEffect } from 'react-use'

import { Hit } from '../Audio/types'
import { useTrackerTableSearchContents } from '../Trackers/useTrackerTableContents'
import TrackerSearchResultsList from './TrackerSearchResultsList'

const minimumSearchCharacters = 3

type SearchResultsProps = {
  conversation: ConversationResponseModel
  wordSearchPhrases: string[]
  setHits: (hits: Hit[]) => void
}

const SearchResults: React.FC<SearchResultsProps> = ({
  conversation,
  wordSearchPhrases,
  setHits,
}) => {
  const wordSearch = useWordSearch(
    conversation.uid,
    wordSearchPhrases,
    minimumSearchCharacters,
  )

  const contents = useTrackerTableSearchContents(
    conversation?.trackerHits ?? [],
    wordSearch,
    conversation?.ignoredPhrases,
  )

  useDeepCompareEffect(() => {
    const hits: Hit[] = contents.results.map((hit) => {
      return {
        id: `${hit.trackerUid}:${hit.words.join('-')}:${hit.timestamp}:${
          hit.speakerId
        }`,
        title: hit.words.join(', '),
        timestamp: hit.timestamp,
        speakerId: hit.speakerId,
        tooltipIsOpen: true,
      }
    })

    setHits?.(hits)
  }, [contents.results, setHits])

  return (
    <TrackerSearchResultsList conversation={conversation} contents={contents} />
  )
}

export default SearchResults
