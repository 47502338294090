import {
  InaccessibleFilter,
  InaccessibleSavedTextFilter,
  PhoneFilterValues,
  SavedPhoneFilter,
  SavedTextFilter,
  TextFilterValues,
} from '@capturi/api-filters'
import { SentimentScore, SentimentSpeaker } from '@capturi/api-sentiment'
import { Speaker } from '@capturi/core'

export type Channel = 'phone' | 'email'
export type Type = 'speech' | 'text'

export const channelToType = (channel: Channel): Type => {
  switch (channel) {
    case 'email':
      return 'text'
    case 'phone':
      return 'speech'
  }
}
export type BaseTrackerState = {
  uids: string[]
}

export type TrackerState = BaseTrackerState & {
  speakerId?: Speaker
  word?: string
  inverted?: boolean
}

export type SentimentState = {
  score: SentimentScore
  speaker: SentimentSpeaker
}

// Active filter state keys
export type ActiveFilterStateKeys = Array<keyof PhoneFilterValues>

export type Segment<T = unknown> = {
  color: string
  label: string
  data: T | null
}

export type SegmentsData<S, T> = {
  summaries: Segment<S>[]
  data: T[]
}

export type PhoneSegmentBuilderState = {
  channel: 'phone'
  values: Partial<PhoneFilterValues>
  savedFilter?: SavedPhoneFilter | InaccessibleFilter
}

export type TextSegmentBuilderState = {
  channel: 'email'
  values: TextFilterValues
  savedTextFilter?: SavedTextFilter | InaccessibleSavedTextFilter
}
