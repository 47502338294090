import {
  Box,
  HStack,
  IconButton,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'
import { MdOutlineThumbDown, MdOutlineThumbUp } from 'react-icons/md'
import { MagicBox } from '../MagicBox'

export interface AiContentCardProps {
  content: {
    header?: {
      title?: string | JSX.Element
      topics?: string
      subTitle?: string
    }
    body: string
  }
  onThumbsUp?: () => void
  onThumbsDown?: () => void
}

export const AiContentCard: React.FC<AiContentCardProps> = ({
  content,
  onThumbsUp,
  onThumbsDown,
}) => {
  const toast = useToast()

  const handleThumbsUp = () => {
    onThumbsUp?.()
    toast({
      title: t`Thank you for the feedback`,
      description: t`You are helping us to improve future AI features`,
      status: 'success',
      duration: 4000,
      isClosable: true,
    })
  }

  const handleThumbsDown = () => {
    onThumbsDown?.()
    toast({
      title: t`Thank you for the feedback`,
      description: t`You are helping us to improve future AI features`,
      status: 'success',
      duration: 4000,
      isClosable: true,
    })
  }

  return (
    <MagicBox pt={2} pr={2} pl={3} pb={3}>
      {content.header && (
        <>
          <HStack justifyContent="space-between" align="start">
            <Box>
              <Text color="gray.600" fontWeight="medium" h={6}>
                {content.header.title}
              </Text>
              {content.header.topics && (
                <Text fontSize="md" mb={4}>
                  {content.header.topics}
                </Text>
              )}
            </Box>
            <Box>
              <IconButton
                aria-label="Thumbs up"
                fontSize="8px"
                icon={<MdOutlineThumbUp />}
                size="xs"
                variant="ghost"
                color="gray.500"
                onClick={handleThumbsUp}
              />
              <IconButton
                aria-label="Thumbs down"
                fontSize="8px"
                icon={<MdOutlineThumbDown />}
                size="xs"
                variant="ghost"
                color="gray.500"
                onClick={handleThumbsDown}
              />
            </Box>
          </HStack>
          <Box>
            <VStack alignItems="start" gap={1}>
              <Text color="gray.600" fontWeight="medium" as="span">
                {content.header.subTitle}
              </Text>
              <Text fontSize="md" whiteSpace="pre-wrap">
                {content.body}
              </Text>
            </VStack>
          </Box>
        </>
      )}

      {!content.header && (
        <Text whiteSpace="pre-wrap" fontSize="md">
          {content.body}
        </Text>
      )}
    </MagicBox>
  )
}
