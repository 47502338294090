import { useModal } from '@capturi/use-modal'
import constate from 'constate'
import { HTTPError } from 'ky'
import { useCallback, useEffect, useState } from 'react'
import { useEffectOnce, useInterval, useNetworkState } from 'react-use'

import AuthService, { shouldBypassAuth } from './AuthService'
import AuthorizationErrorModal from './AuthorizationErrorModal'

const useAuth = (): {
  isAuthenticated: boolean
  isAuthenticating: boolean
} => {
  const [openErrorModal] = useModal(AuthorizationErrorModal)

  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [authTokenInvalid, setAuthTokenInvalid] = useState(false)
  const { online } = useNetworkState()

  const authenticate = useCallback(async () => {
    try {
      setIsAuthenticating(true)
      await AuthService.refreshAccessToken()
      const params = new URLSearchParams(window.location.search)

      const org = params.get('org')
      if (org && AuthService.currentOrganization !== org) {
        try {
          await AuthService.changeOrganization(org)
        } catch (_error) {
          openErrorModal()
        }
      }

      setIsAuthenticated(true)
    } catch (error) {
      setIsAuthenticated(false)
      if (error instanceof HTTPError && error.response.status === 401) {
        setAuthTokenInvalid(true)
      }
    } finally {
      setIsAuthenticating(false)
    }
  }, [openErrorModal])

  useEffectOnce(() => {
    if (shouldBypassAuth) {
      setIsAuthenticated(true)
      setIsAuthenticating(false)
    } else {
      authenticate()
    }
  })

  useEffect(() => {
    if (online && !authTokenInvalid && !isAuthenticating && !isAuthenticated) {
      authenticate()
    }
  }, [
    authTokenInvalid,
    authenticate,
    isAuthenticated,
    isAuthenticating,
    online,
  ])

  useInterval(
    async () => {
      try {
        await AuthService.refreshAccessToken()
      } catch (_error) {
        setIsAuthenticated(false)
      }
    },
    // if user can authenticate, check every 7 minutes if session is still valid
    isAuthenticated && !shouldBypassAuth ? 420000 : null,
  )

  return {
    isAuthenticated,
    isAuthenticating,
  }
}

const [AuthProvider, useAuthContext] = constate(useAuth)

export { AuthProvider, useAuthContext }
