import tracker from '@capturi/analytics'
import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { useScrollable } from '@capturi/react-utils'
import { ShowModal } from '@capturi/use-modal'
import {
  Box,
  Divider,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { useShortcut } from '@shopify/react-shortcuts'
import { CreateTrackerOrFolderButton } from 'pages/TrackersPage/components/TrackerButtons'
import { useLastSelectedTracker } from 'pages/TrackersPage/hooks/useLastSelectedTracker'
import React, { useEffect, useMemo, useRef } from 'react'
import { MdClose, MdSearch } from 'react-icons/md'
import { createSearchParams, useNavigate, useParams } from 'react-router'
import { Event, logEvent } from '../events'
import { useSearchTermContext } from '../hooks/useSearchTermContext'
import { useTagsContext } from '../hooks/useTagsContext'
import getRoutes from '../routes'
import { ListItems } from '../useMapListItems'
import FilterTagsPopover from './FilterTagsPopover'
import { CreateUpdateTrackerFolderDialogProps } from './TrackerFolderForm'
import { TrackerListFolder } from './TrackerListFolder'
import TrackerListItem from './TrackerListItem'

const trackerSearch = (
  trackers: ListItems['trackers'],
  lowercasedSearchQuery: string,
): ListItems['trackers'] =>
  trackers.filter(
    (t) =>
      t.trackerName.toLowerCase().includes(lowercasedSearchQuery) ||
      t.speech?.phrases?.some((phrase) =>
        phrase.toLowerCase().includes(lowercasedSearchQuery),
      ) ||
      t.text?.phrases?.some((phrase) =>
        phrase.toLowerCase().includes(lowercasedSearchQuery),
      ),
  )

type Props = {
  folders: ListItems[]
  root: ListItems['trackers']
  trackerUidsByFolderUids: Map<string | null, Set<string>>
  onMoveTrackerToFolder: (trackerUid: string, folderUid: string | null) => void
  onCreateEditFolder: ShowModal<CreateUpdateTrackerFolderDialogProps>
  onDeleteFolder: (folderUid: string, folderName: string) => void
}

const TrackerList: React.FC<Props> = ({
  folders,
  root,
  trackerUidsByFolderUids,
  onMoveTrackerToFolder: onMoveUpdateTrackerFolder,
  onCreateEditFolder,
  onDeleteFolder,
}) => {
  const currentUser = useCurrentUser()
  const { uid: trackerUid } = useParams()

  const { enableMasterTracker } = useFeatureFlags()
  const navigate = useNavigate()
  const listRef = useRef<HTMLDivElement | null>(null)

  const [selectedTags, setSelectedTags] = useTagsContext()
  const [searchTerm, setSearchTerm] = useSearchTermContext()

  const handleSetSelectedTags = React.useCallback(
    (tags: string[]): void => {
      setSelectedTags(tags)
      logEvent(Event.FilterTrackersByTag)
    },
    [setSelectedTags],
  )

  const { lastSelectedTracker, setLastSelectedTracker } =
    useLastSelectedTracker()

  const searchInputRef = React.useRef<HTMLInputElement>(null)

  useShortcut(
    ['k'],
    () => {
      if (searchInputRef.current) {
        searchInputRef.current.focus()
      }
    },
    { held: [['Control'], ['Meta']] },
  )

  //each time the selected tracker changes, we update local storage
  if (
    trackerUid != null &&
    trackerUid !== '' &&
    lastSelectedTracker !== trackerUid
  ) {
    setLastSelectedTracker(trackerUid)
  }
  const sidebarHeight = useScrollable(listRef, 32)

  const selectTracker = (uid: string): void => {
    let newSearchParams = ''
    if (selectedTags?.length) {
      newSearchParams = createSearchParams({
        tags: selectedTags,
      }).toString()
    }

    navigate(
      newSearchParams
        ? {
            pathname: getRoutes().tracker({ trackerUid: uid }),
            search: newSearchParams,
          }
        : uid,
    )
  }

  const selectedTagsSet = useMemo(
    () => new Set(selectedTags || []),
    [selectedTags],
  )

  const rootTrackersFilteredByTrackerTags = useMemo(() => {
    if (selectedTagsSet.size > 0) {
      return root.filter((t) => t.tags.some((s) => selectedTagsSet.has(s)))
    }
    return root
  }, [root, selectedTagsSet])

  const filteredRootTrackers = useMemo(() => {
    if (!searchTerm) return rootTrackersFilteredByTrackerTags

    const lowercasedSearchQuery = searchTerm?.toLowerCase()
    return trackerSearch(
      rootTrackersFilteredByTrackerTags,
      lowercasedSearchQuery,
    )
  }, [rootTrackersFilteredByTrackerTags, searchTerm])

  const foldersFilteredByTrackerTags = useMemo(() => {
    if (selectedTagsSet.size > 0) {
      return folders.reduce<ListItems[]>((memo, folder) => {
        const trackers = folder.trackers.filter((t) =>
          t.tags.some((s) => selectedTagsSet.has(s)),
        )
        if (trackers.length) {
          memo.push({ ...folder, trackers })
        }
        return memo
      }, [])
    }
    return folders
  }, [folders, selectedTagsSet])

  const filteredTrackers = useMemo(() => {
    if (!searchTerm) return foldersFilteredByTrackerTags

    const lowercasedSearchQuery = searchTerm.toLowerCase()

    return foldersFilteredByTrackerTags.reduce<ListItems[]>((memo, item) => {
      if (lowercasedSearchQuery) {
        const filteredTrackers = trackerSearch(
          item.trackers,
          lowercasedSearchQuery,
        )

        if (filteredTrackers.length) {
          memo.push({
            ...item,
            trackers: filteredTrackers,
            searchMatchType: 'tracker',
          })
        } else if (item.title.toLowerCase().includes(lowercasedSearchQuery)) {
          memo.push({ ...item, searchMatchType: 'folder' })
        }
      }
      return memo
    }, [])
  }, [foldersFilteredByTrackerTags, searchTerm])

  useEffect(() => {
    if (!trackerUid && lastSelectedTracker) {
      navigate(lastSelectedTracker)
    } else if (
      !(trackerUid || lastSelectedTracker) &&
      filteredRootTrackers.length
    ) {
      navigate(filteredRootTrackers[0].trackerUid)
    }
  }, [filteredRootTrackers, lastSelectedTracker, navigate, trackerUid])

  //Tracker list
  return (
    <Stack position="sticky" top="0.5rem" spacing={2} flex="0 0 246px">
      <HStack spacing={1} pr={0}>
        <Tooltip
          hasArrow
          label={t`Search for both trackers AND tracker phrases`}
          placement="top"
        >
          <InputGroup size="sm">
            <InputLeftElement pointerEvents="none">
              <Icon fontSize="14px" as={MdSearch} />
            </InputLeftElement>
            <Input
              borderColor="gray.200"
              ref={searchInputRef}
              borderRadius={4}
              onChange={(e) => setSearchTerm(e.target.value)}
              onFocus={() => tracker.event('tracker_search_focus')}
              placeholder={t`Search...`}
              value={searchTerm ?? ''}
              _hover={{
                borderColor: 'gray.300',
              }}
            />
            {searchTerm && searchTerm.length > 0 ? (
              <InputRightElement cursor="pointer">
                <Icon
                  fontSize="14px"
                  as={MdClose}
                  onClick={() => setSearchTerm('')}
                />
              </InputRightElement>
            ) : null}
          </InputGroup>
        </Tooltip>
        <FilterTagsPopover
          selectedTags={selectedTags}
          setSelectedTags={handleSetSelectedTags}
        />
        {currentUser.permissions.editTracker && (
          <CreateTrackerOrFolderButton
            onCreateFolder={() => {
              onCreateEditFolder({
                enableMasterTracker: enableMasterTracker,
              })
            }}
          />
        )}
      </HStack>
      <Stack
        spacing={0}
        height={sidebarHeight}
        minH="450px"
        overflowY="auto"
        overflowX="hidden"
        ref={listRef}
        borderY="1px solid"
        borderColor="gray.200"
        py={2}
        pr={2}
      >
        {filteredTrackers.length > 0 || filteredRootTrackers.length > 0 ? (
          <>
            {filteredTrackers.map((i) => (
              <TrackerListFolder
                searchMatchType={i.searchMatchType}
                key={i.uid + i.searchMatchType}
                currentSelectedTrackerUid={
                  trackerUid
                    ? trackerUidsByFolderUids.get(i.uid)?.has(trackerUid)
                      ? trackerUid
                      : undefined
                    : undefined
                }
                item={i}
                onSelectTracker={selectTracker}
                onMoveTrackerToFolder={onMoveUpdateTrackerFolder}
                onEditFolder={onCreateEditFolder}
                onDeleteFolder={onDeleteFolder}
              />
            ))}
            <Divider pt={2} width="80px" opacity="1" />
            <Box pt={4}>
              {filteredRootTrackers.map((tracker) => (
                <TrackerListItem
                  key={tracker.trackerUid}
                  tracker={tracker}
                  showProgress
                  isSelected={tracker.trackerUid === trackerUid}
                  onTrackerSelected={selectTracker}
                  onMoveToFolder={onMoveUpdateTrackerFolder}
                />
              ))}
            </Box>
          </>
        ) : (
          <Text align="center" color="gray.600" py="6" w="100%">
            <Trans>No results...</Trans>
          </Text>
        )}
      </Stack>
    </Stack>
  )
}

export default TrackerList
