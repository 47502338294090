import 'react-day-picker/dist/style.css'

import { Flex, Input, Stack } from '@chakra-ui/react'
import { Global, css } from '@emotion/react'
import { i18n } from '@lingui/core'
import { format, isDate } from 'date-fns'
import React from 'react'
import { ActiveModifiers, DayPickerProps } from 'react-day-picker'

import LocalizedDayPicker from './LocalizedDayPicker'
import NavBar from './NavBar'
import createStyle from './style'

const CLS_NAME = 'capturi-dp'
const cssStyle = createStyle(CLS_NAME)

export type DatePickerProps = DayPickerProps & {
  // Controlled value
  value?: Date
  defaultValue?: Date
  onSelectDate?: (date: Date) => void
  inputPlaceholder?: string
  showInputs?: boolean
}

export function defaultFormat(d: Date): string {
  return isDate(d) ? format(d, 'yyyy-MM-dd') : ''
}

const DatePicker: React.FC<DatePickerProps> = ({
  value: controlledValue,
  defaultValue,
  onSelectDate,
  inputPlaceholder = '',
  showInputs = false,
  disabled,
}) => {
  const { current: isControlled } = React.useRef(controlledValue != null)

  const dateInputRef = React.useRef<HTMLInputElement>(null)

  const [value, setValue] = React.useState<Date | undefined>(defaultValue)
  const _value = isControlled && controlledValue ? controlledValue : value
  const [month, setMonth] = React.useState<Date>(_value ?? new Date())
  const dateInputValue = _value
    ? i18n.date(_value, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    : undefined

  const handleDayClick = (
    _day: Date | undefined,
    selectedDay: Date,
    activeModifiers: ActiveModifiers,
  ): void => {
    if (activeModifiers.disabled) {
      return
    }

    setValue(selectedDay)
    onSelectDate?.(selectedDay)
  }

  // Page to the selected month
  const pageToMonth = (date?: Date): void => {
    if (date) {
      setMonth(date)
    }
  }

  const handleInputFocus = (): void => {
    pageToMonth(_value)
  }

  return (
    <Flex alignItems="center" flexDir="column" overflow="auto">
      {showInputs && (
        <Stack
          as="header"
          direction="row"
          spacing={[2, 4]}
          alignItems="center"
          p={[2, 4]}
          maxW="273px"
        >
          <Input
            ref={dateInputRef}
            size="sm"
            value={dateInputValue}
            onFocus={handleInputFocus}
            placeholder={inputPlaceholder}
            isReadOnly
            minW={0}
            flex={1}
          />
        </Stack>
      )}
      <LocalizedDayPicker
        className={CLS_NAME}
        mode="single"
        onSelect={handleDayClick}
        selected={_value}
        components={{ Caption: (p) => <NavBar {...p} /> }}
        month={month}
        onMonthChange={setMonth}
        disabled={disabled}
        weekStartsOn={1}
      />
      <Global
        styles={css`
          ${cssStyle}
        `}
      />
    </Flex>
  )
}

export { DatePicker }
