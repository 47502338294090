import { AccessLevel } from '@capturi/api-shared'
import request, { ResponseError } from '@capturi/request'
import { UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'

import {
  BaseTracker,
  GetTrackerDependentsResponseModel,
  InacessibleTracker,
  Tracker,
} from '../models'
import { baseUrl, cacheKey } from './constants'

const POLL_INTERVAL = 5000

export type TrackerResponse = {
  trackers: Tracker[]
}
type InaccessibleTrackerResponse = {
  trackers: (Tracker | InacessibleTracker)[]
}

type UseTrackerDeps = {
  fetchTrackerDependants: (
    uid: string,
  ) => Promise<GetTrackerDependentsResponseModel>
}

const compare = Intl.Collator().compare

const selectorOneAccessible =
  (trackerUid: string | undefined): ((data: Tracker) => Tracker | undefined) =>
  (data) => {
    return trackerUid !== undefined && data.accessLevel !== 'None'
      ? data
      : undefined
  }

export const useTracker = (
  trackerUid: string | undefined,
  options: {
    shouldPollForUpdates: boolean
  } = { shouldPollForUpdates: false },
): UseQueryResult<Tracker | undefined, ResponseError> =>
  useQuery({
    queryKey: [cacheKey, trackerUid],
    queryFn: () =>
      request.get<Tracker>(`trackers/${trackerUid}/?api-version=3.3`),
    select: selectorOneAccessible(trackerUid),
    enabled: !!trackerUid,
    refetchInterval: options.shouldPollForUpdates ? POLL_INTERVAL : false,
    gcTime: Number.POSITIVE_INFINITY,
  })

const selectorWithAccessLevel =
  (accessLevel?: AccessLevel): ((data: TrackerResponse) => Tracker[]) =>
  (data) => {
    return data.trackers
      .filter((d) => {
        if (accessLevel === undefined) {
          return d.accessLevel !== 'None'
        }
        return d.accessLevel === accessLevel
      })
      .sort((a, b) => compare(a.name, b.name))
  }
const selectorAll = (
  data: InaccessibleTrackerResponse,
): (Tracker | InacessibleTracker)[] => {
  return data.trackers.sort((a, b) => compare(a.name, b.name))
}

export const useTrackers = ({
  accessLevel,
  refetchInterval,
}: {
  accessLevel?: AccessLevel
  refetchInterval?: number
} = {}): UseQueryResult<BaseTracker[], ResponseError> =>
  useQuery({
    queryKey: [cacheKey],
    queryFn: () =>
      request.get<TrackerResponse>(`${baseUrl}&includeInaccessible=true`),

    select: selectorWithAccessLevel(accessLevel),
    refetchInterval: refetchInterval ?? false,
    staleTime: 60_000, // 1 minute
  })

export const useAllTrackers = (): UseQueryResult<
  (Tracker | InacessibleTracker)[],
  ResponseError
> =>
  useQuery({
    queryKey: [cacheKey],
    queryFn: () =>
      request.get<InaccessibleTrackerResponse>(
        `${baseUrl}&includeInaccessible=true`,
      ),
    select: selectorAll,
  })

export const useTrackerDeps = (): UseTrackerDeps => {
  const queryClient = useQueryClient()
  return useMemo(
    () => ({
      fetchTrackerDependants: (uid: string) =>
        queryClient.fetchQuery({
          queryKey: [cacheKey, 'dependants', uid],
          queryFn: () =>
            request.get<GetTrackerDependentsResponseModel>(
              `trackers/${uid}/dependents/?api-version=3.3`,
            ),
        }),
    }),
    [queryClient],
  )
}

export const useTrackerByUid = <T extends BaseTracker | InacessibleTracker>(
  trackers: Array<T> | undefined,
): Record<string, T> | undefined => {
  return useMemo(
    () =>
      trackers?.reduce<Record<string, T>>((memo, t) => {
        memo[t.uid] = t
        return memo
      }, {}),
    [trackers],
  )
}
