import analytics from '@capturi/analytics'
import { useDeleteConversation } from '@capturi/api-conversations'
import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { getErrorMessage } from '@capturi/request'
import { useOrganization } from '@capturi/stores'
import { Emoji } from '@capturi/ui-components'
import { useConfirm } from '@capturi/use-confirm'
import { useModal } from '@capturi/use-modal'
import {
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Trans, select, t } from '@lingui/macro'
import { ViewRaw } from 'components/ViewRaw'
import { routes } from 'pages/Conversations'
import React from 'react'
import {
  MdCopyAll,
  MdDelete,
  MdFileDownload,
  MdMoreVert,
  MdOpenInNew,
} from 'react-icons/md'
import { useNavigate } from 'react-router'
import { useCopyToClipboard } from 'react-use'
import useDownloadConversation from './useDownloadConversation'
import useDownloadTranscription from './useDownloadTranscription'

type Props = {
  conversationUid: string
  showGoToConversation: boolean
  conversationSource: string
  conversationExternalUid: string
}

const SettingsMenu: React.FC<Props> = ({
  conversationUid,
  showGoToConversation,
  conversationSource,
  conversationExternalUid,
}) => {
  const user = useCurrentUser()
  const confirm = useConfirm()
  const toast = useToast()
  const [, copy] = useCopyToClipboard()
  const navigate = useNavigate()
  const { organizationType } = useOrganization()
  const { showTranscriptionDownloadButtons } = useFeatureFlags()
  const [openRawCase] = useModal(ViewRaw)

  const downloadConversation = useDownloadConversation(conversationUid)
  const downloadTranscription = useDownloadTranscription(conversationUid)
  const { mutateAsync } = useDeleteConversation(conversationUid)
  if (!(user.isAdmin || user.permissions.playback)) return null

  const handleDelete = async (): Promise<void> => {
    try {
      await mutateAsync()

      toast({
        title: t`Conversation deleted`,
        status: 'success',
      })
      //The conversations stays open efter deleteting
      //I think the easiest way to fix this would be to mutate the conversations list cache, and removing the deletet item
      //But that would require a migration to react-query, I think, because cache key
    } catch (error) {
      const errorMsg = getErrorMessage(error)
      toast({
        title: t`Ouch, conversation was not deleted`,
        description: errorMsg,
        status: 'error',
      })
      throw error
    }
  }

  const handleCancelDeleteAction = async (): Promise<void> => {
    try {
      await confirm({
        title: t`Are you sure?`,
        description: (
          <>
            <Text mb="4">
              {select(organizationType, {
                public:
                  'This will delete the recording, the transcription as well as the phone number of the citizen.',
                other:
                  'This will delete the recording, the transcription as well as the phone number of the customer.',
              })}
            </Text>
            <Text fontWeight="600">
              <Trans>
                This will also delete snippets from this conversation from the
                library!
              </Trans>
            </Text>
          </>
        ),
        cancelText: t`Cancel`,
        confirmText: t`Delete`,
      })
      handleDelete()
    } catch {
      // cancelled
    }
  }

  const handleCopyConversationUid = (): void => {
    analytics.event('conversationPage_copyConversationUid')
    copy(conversationUid)
    toast({
      status: 'info',
      title: t`Copied conversation ID to clipboard`,
      description: conversationUid,
    })
  }
  const handleGoToConversation = (): void => {
    analytics.event('megaDrawer_openOnConversationPage')
    navigate(routes.conversation(conversationUid))
  }

  return (
    <Menu placement={'bottom-end'}>
      <MenuButton color="gray.600">
        <Icon as={MdMoreVert} boxSize={6} />
      </MenuButton>

      <MenuList>
        <MenuItem onClick={handleCopyConversationUid}>
          <MdCopyAll />
          <Text ml="2">
            <Trans>Copy conversation ID to clipboard</Trans>
          </Text>
        </MenuItem>
        {showGoToConversation && (
          <MenuItem onClick={handleGoToConversation}>
            <MdOpenInNew />
            <Text ml="2">
              <Trans>View on conversation page</Trans>
            </Text>
          </MenuItem>
        )}
        {user.permissions.download && (
          <>
            {user.permissions.playback && (
              <MenuItem onClick={downloadConversation}>
                <MdFileDownload />
                <Text ml="2">
                  <Trans>Download audio</Trans>
                </Text>
              </MenuItem>
            )}
            {showTranscriptionDownloadButtons && (
              <>
                <MenuItem onClick={() => downloadTranscription(false)}>
                  <MdFileDownload />
                  <Text ml="2">
                    <Trans>Download transcription</Trans>
                  </Text>
                </MenuItem>
                <MenuItem onClick={() => downloadTranscription(true)}>
                  <MdFileDownload />
                  <Text ml="2">
                    <Trans>Download anonymized transcription</Trans>
                  </Text>
                </MenuItem>
              </>
            )}
          </>
        )}

        {user.isAdmin && (
          <>
            <MenuDivider />
            <MenuItem onClick={handleCancelDeleteAction}>
              <MdDelete />
              <Text ml="2">
                <Trans>Delete</Trans>
              </Text>
            </MenuItem>
          </>
        )}
        {user.hasSuperPowers && conversationSource && (
          <MenuItem
            onClick={() =>
              openRawCase({
                externalUid: conversationExternalUid,
                integrationType: conversationSource,
              })
            }
          >
            <Emoji symbol="🥩" />
            <Text ml="2">View Raw</Text>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}

export default SettingsMenu
