import analytics from '@capturi/analytics'
import { useScores } from '@capturi/api-scoring'
import { Button } from '@capturi/ui-components'
import { Flex, HStack, Heading } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { routes as scoringRoutes } from 'pages/ScoringPage/routes'
import React from 'react'
import { useNavigate } from 'react-router'
import ScoreList from './components/ScoreList'

export const ScoreWidget: React.FC = () => {
  const navigate = useNavigate()
  const { data, isLoading } = useScores()

  if (!data?.length && !isLoading) {
    return null
  }

  return (
    <Flex flexDir="column">
      <HStack justify="space-between">
        <Heading
          fontSize="2xl"
          textShadow="textExtended"
          mb={3}
          data-stonly="Scores"
          width="fit-content"
        >
          <Trans>Scores</Trans>
        </Heading>
        <Button
          onClick={() => {
            analytics.event('top_drivers_explore_more')
            navigate(scoringRoutes.main())
          }}
          primary
          size="xs"
          variant="link"
        >
          <Trans>Explore more</Trans>
        </Button>
      </HStack>
      <ScoreList />
    </Flex>
  )
}
