import type { PhoneFilterValues } from '@capturi/api-filters'
import type { FilterDefinition } from './components/PhoneFilter'
type FilterValueKey = keyof PhoneFilterValues

export const archiveOverrides: Partial<{
  [key in FilterValueKey]: Partial<FilterDefinition>
}> = {
  customers: {},
  duration: { isAvailable: () => false },
  keyTopics: { isAvailable: () => false },
  labels: { isAvailable: () => false },
  notTrackers: { isAvailable: () => false },
  qaIsReviewed: { isAvailable: () => false },
  repeatCalls: { isAvailable: () => false },
  sentiment: { isAvailable: () => false },
  scores: { isAvailable: () => false },
  status: { isAvailable: () => false },
  subjects: { isAvailable: () => false },
  teamUids: { isAvailable: () => false },
  trackers: { isAvailable: () => false },
  speakers: { isAvailable: () => false },
}
