import ky, { HTTPError } from 'ky'

declare global {
  interface Window {
    env?: {
      apiUrl: string
    }
  }
}

const isDev = process.env.NODE_ENV === 'development'
const useProxyPrefixPath = process.env.USE_CAPTURI_DEV_PROXY === 'true' || isDev

const remoteOrigin = window.env?.apiUrl || 'https://api.capturi.ai'
const prefixUrl = `${useProxyPrefixPath ? '/api' : remoteOrigin}/authentication`

const createProviderUrl = (
  provider: string,
  params: URLSearchParams,
): string => {
  return useProxyPrefixPath
    ? `${remoteOrigin}/authentication/app/${provider}?${params}`
    : `${remoteOrigin}/authentication/${provider}?${params}`
}

const api = ky.create({
  prefixUrl,
  credentials: 'include',
  retry: 0,
})

type RefreshTokenResponseModel = {
  ttl: number
  org: string
  accessToken: string
  refreshToken: string
}

export const shouldBypassAuth =
  new URLSearchParams(window.location.search).get('bypass_auth') != null

class AuthService {
  #pendingAccessTokenPromise?: Promise<void>
  #expireTime?: number
  currentOrganization?: string
  listOrganizationsURL = 'authentication/me/organizations'
  refreshToken = ''
  accessToken = ''

  get pendingAccessTokenPromise(): Promise<void> | undefined {
    return this.#pendingAccessTokenPromise
  }

  isRefreshUrl(url: string): boolean {
    return url.includes(`${prefixUrl}/refresh`)
  }

  validateAuthentication(): Promise<void> | void {
    if (this.#expireTime && this.#expireTime > Date.now()) return

    return this.refreshAccessToken()
  }

  async changeOrganization(
    organizationUid: string,
    config?: { dontGoToOrg: boolean },
  ): Promise<void> {
    const resp = await api
      .post('me/change-org', {
        headers: useProxyPrefixPath
          ? {
              refresh: `Bearer ${this.refreshToken}`,
              authorization: `Bearer ${this.accessToken}`,
            }
          : undefined,
        json: { uid: organizationUid },
      })
      .json<{ refreshToken: string; accessToken: string; org: string }>()

    this.accessToken = resp.accessToken
    this.refreshToken = resp.refreshToken
    localStorage.setItem('refreshToken', resp.refreshToken)
    localStorage.setItem('accessToken', resp.accessToken)

    if (!(config?.dontGoToOrg === true)) {
      window.location.href = window.location.origin
    }
  }

  refreshAccessToken(): Promise<void> | undefined {
    if (shouldBypassAuth) return
    if (this.#pendingAccessTokenPromise) {
      return this.#pendingAccessTokenPromise
    }

    if (
      useProxyPrefixPath &&
      this.refreshToken === '' &&
      this.accessToken === ''
    ) {
      const params = new URLSearchParams(document.location.search)
      if (params.has('refreshToken') && params.has('accessToken')) {
        this.refreshToken = params.get('refreshToken') || ''
        this.accessToken = params.get('accessToken') || ''

        params.delete('refreshToken')
        params.delete('accessToken')
        window.history.replaceState(
          null,
          '',
          `${window.location.pathname}?${params.toString()}`,
        )
        localStorage.setItem('refreshToken', this.refreshToken)
        localStorage.setItem('accessToken', this.accessToken)
      } else {
        this.refreshToken = localStorage.getItem('refreshToken') || ''
        this.accessToken = localStorage.getItem('accessToken') || ''
      }
    }

    this.#pendingAccessTokenPromise = api
      .get('refresh', {
        headers: useProxyPrefixPath
          ? {
              refresh: `Bearer ${this.refreshToken}`,
              authorization: `Bearer ${this.accessToken}`,
            }
          : undefined,
      })
      .json<RefreshTokenResponseModel>()
      .then((resp) => {
        this.#expireTime = Date.now() + resp.ttl
        this.currentOrganization = resp.org
        if (useProxyPrefixPath && resp.accessToken && resp.refreshToken) {
          this.#expireTime = Date.now() + 3600000 // 3600000 is the default ttl
          this.accessToken = resp.accessToken
          this.refreshToken = resp.refreshToken
          localStorage.setItem('refreshToken', resp.refreshToken)
          localStorage.setItem('accessToken', resp.accessToken)
        }
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          this.accessToken = ''
          this.refreshToken = ''
          localStorage.removeItem('refreshToken')
          localStorage.removeItem('accessToken')
          throw e
        }
      })
      .finally(() => {
        this.#pendingAccessTokenPromise = undefined
      })
    return this.#pendingAccessTokenPromise
  }

  async signOut(): Promise<void> {
    try {
      await api.post('sign-out')
      this.accessToken = ''
      this.refreshToken = ''
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('accessToken')
    } catch (_e) {
      // doesn't matter
    } finally {
      window.location.href = window.location.origin
    }
  }

  addOrganization = async (
    invite: string,
    language: string,
  ): Promise<{ org: string }> => {
    try {
      return await api
        .post('me/organizations', {
          json: {
            invite,
            language,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        })
        .json<{ org: string }>()
    } catch (error) {
      let e = error
      try {
        if (error instanceof HTTPError) {
          e = await error.response.json()
        }
      } catch (_error) {
        throw e
      }
      throw e
    }
  }

  generateAuthURL = (provider: string, language: string): string => {
    const params = new URLSearchParams(window.location.search)

    const invite = params.get('invite')
    params.delete('invite') //Remove invite from the return url
    const org = params.get('org')
    params.delete('org') //Remove org from the return url

    const { pathname, origin } = window.location
    //Generate the return url
    const returnUrl = encodeURI(`${origin}${pathname}?${params.toString()}`)

    //Create the return url param
    const newParams = new URLSearchParams()
    if (org) newParams.append('org', org)
    newParams.append('return_url', returnUrl)
    newParams.append('language', language)
    newParams.append(
      'time_zone',
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    )
    if (invite) newParams.append('invite', invite) //Add the invite param if it exist

    return createProviderUrl(provider, newParams)
  }
}

export default new AuthService()
