import analytics from '@capturi/analytics'
import { AuthService } from '@capturi/auth'
import { useCurrentUser } from '@capturi/core'
import { ErrorBoundary } from '@capturi/react-utils'
import { useOrganization } from '@capturi/stores'
import { Spinner } from '@capturi/ui-components'
import {
  Box,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { MenuLink } from 'components/Link'
import { UnrestrictedAccess } from 'components/UnrestrictedAccess/UnrestrictedAccess'
import useUserPermissions from 'components/UnrestrictedAccess/useUserPermissions'
import React, { Suspense } from 'react'
import { MdMoreHoriz } from 'react-icons/md'

const AsyncProfileMenu: React.FC = () => {
  const user = useCurrentUser({ suspense: false })
  const { name } = useOrganization()

  const { togglePermission, inProgress, permissions } = useUserPermissions()

  const handleSignOut = (): void => {
    analytics.reset()
    AuthService.signOut()
  }
  return (
    <Menu autoSelect={false} placement={'bottom-end'} closeOnSelect={false}>
      <MenuButton
        display="block"
        borderRadius="full"
        p="1"
        _hover={{
          bg: 'gray.400',
        }}
        _active={{
          bg: 'gray.500',
        }}
      >
        <Icon
          as={MdMoreHoriz}
          boxSize="6"
          focusable={false}
          color="currentColor"
          aria-hidden
          display="block"
          p="1"
        />
      </MenuButton>

      <MenuList color="black">
        {permissions.organization && (
          <>
            <Box px={4} fontSize="sm">
              <Text fontWeight="medium">{user.name}</Text>
              <Text color="textMuted">{name}</Text>
              <UnrestrictedAccess
                user={user}
                permissions={permissions}
                inProgress={inProgress}
                togglePermission={togglePermission}
              />
            </Box>
            <MenuDivider />
          </>
        )}
        <MenuGroup title={t`Help`}>
          <MenuItem data-stonly="onboarding-guides">
            <Trans>Onboarding guides</Trans>
          </MenuItem>
          {user.isAdmin && (
            <MenuItem data-stonly="What's new">
              <Trans>What's new</Trans>
            </MenuItem>
          )}
          <MenuLink to="https://capturi.stonly.com/kb/">
            <Trans>Support</Trans>
          </MenuLink>
          <MenuLink to="https://capturi.cnflx.io">
            <Trans>Idea board</Trans>
          </MenuLink>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup>
          <MenuItem onClick={handleSignOut}>
            <Trans>Sign out</Trans>
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}

const ProfileMenu: React.FC = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <AsyncProfileMenu />
      </Suspense>
    </ErrorBoundary>
  )
}

export default ProfileMenu
