import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  chakra,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { FiChevronDown } from 'react-icons/fi'
import {
  DailyScheduleType,
  MonthlyScheduleType,
  Period,
  Schedule,
  WeeklyScheduleType,
} from '../../../../shared/Subscriptions/types'
import PeriodWrapper from '../Period/Period'
import RecurrenceMonthly from './RecurrenceMonthly'
import RecurrenceWeekly from './RecurrenceWeekly'
const recurrenceItems = ['Daily', 'Weekly', 'Monthly'] as const

const defaultWeekly: WeeklyScheduleType = {
  type: 'Weekly',
  days: ['Monday'],
} as const

const defaultMonthly: MonthlyScheduleType = {
  type: 'Monthly',
  dayOfMonth: 'Last',
} as const

const defaultDaily: DailyScheduleType = {
  type: 'Daily',
} as const

const defaults = {
  Weekly: defaultWeekly,
  Monthly: defaultMonthly,
  Daily: defaultDaily,
} as const

const translateLabels = (type: 'Daily' | 'Monthly' | 'Weekly') => {
  switch (type) {
    case 'Daily':
      return t`Day`
    case 'Weekly':
      return t`Week`
    case 'Monthly':
      return t`Month`
  }
}

const translateMonthLabel = (type: 'Last' | 'First'): string => {
  switch (type) {
    case 'Last':
      return t`current month`
    case 'First':
      return t`previous month`
  }
}

const Recurrence: React.FC<{
  schedule: Schedule
  setSchedule: (schedule: Schedule) => void
  period: Period
  setPeriod: (period: Period) => void
}> = ({ schedule, setSchedule, period, setPeriod }) => {
  return (
    <>
      <FormControl>
        <FormLabel>
          <Trans>Recurrence</Trans>
        </FormLabel>
      </FormControl>
      <Box>
        <Text color="gray.600">
          <Trans>Repeat every</Trans>
        </Text>
      </Box>
      <HStack>
        <Flex justify="flex-start">
          <Menu>
            <MenuButton
              variant="outline"
              as={Button}
              rightIcon={<FiChevronDown />}
            >
              {translateLabels(schedule.type)}
            </MenuButton>
            <MenuList>
              {recurrenceItems.map((item) => (
                <MenuItem
                  fontWeight={item === schedule.type ? 'medium' : 'initial'}
                  key={item}
                  onClick={() => {
                    setSchedule(defaults[item])
                  }}
                >
                  {translateLabels(item)}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          {schedule.type === 'Weekly' && (
            <RecurrenceWeekly schedule={schedule} setSchedule={setSchedule} />
          )}
          {schedule.type === 'Monthly' && (
            <RecurrenceMonthly schedule={schedule} setSchedule={setSchedule} />
          )}
        </Flex>
      </HStack>
      {/* if "Last" is selected in month recurrence, set month to "Current". If
      "First", set month to "Previous" */}
      {schedule.type === 'Monthly' ? (
        <Text color="gray.600" fontSize="xs" mt={1}>
          <Trans>
            Reports will be send out in the morning with data from the
          </Trans>{' '}
          <chakra.span fontWeight="medium">
            {translateMonthLabel(schedule.dayOfMonth)}
          </chakra.span>
        </Text>
      ) : (
        <Text color="gray.600" fontSize="xs" mt={1}>
          <Trans>Reports will be send out in the morning</Trans>
        </Text>
      )}
      {schedule.type !== 'Monthly' && (
        <Box mt={4}>
          <PeriodWrapper period={period} setPeriod={setPeriod} />
        </Box>
      )}
    </>
  )
}

export default Recurrence
