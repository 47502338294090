import { ErrorBoundaryWithFallbackComponent } from '@capturi/react-utils'
import { Spinner } from '@capturi/ui-components'
import { HttpErrorBoundaryFallback } from 'components/ErrorBoundaryFallback'
import React from 'react'
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router'

export { default as routes } from './routes'

const UsersList = React.lazy(() => import('./UsersList'))
const UserCoaching = React.lazy(() => import('./UserCoaching'))

const UserCoachingPage: React.FC = () => {
  const { userUid } = useParams()

  //This cannot happen, react router will only load this route if there is a param
  if (!userUid) return null
  return <UserCoaching userUid={userUid} />
}

export const CoachingPage: React.FC = () => {
  const { pathname } = useLocation()
  return (
    <ErrorBoundaryWithFallbackComponent
      FallbackComponent={HttpErrorBoundaryFallback}
      resetKeys={[pathname]}
    >
      <React.Suspense fallback={<Spinner display="block" m="5rem auto" />}>
        <Routes>
          <Route path="/" element={<UsersList />} />
          <Route path="/:userUid" element={<UserCoachingPage />} />
          <Route
            path="/library"
            element={<Navigate to="/coaching" replace />}
          />
        </Routes>
      </React.Suspense>
    </ErrorBoundaryWithFallbackComponent>
  )
}
