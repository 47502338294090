import { Range } from '@capturi/api-filters'
import { Button, DurationInput } from '@capturi/ui-components'
import { OnChangeValue, Select, SelectOption } from '@capturi/ui-select'
import { Box, Divider, Flex, Stack } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useEffect, useState } from 'react'

import {
  FilterCriteriaComponentBaseProps,
  FilterCriteriaSelect,
} from '../../components/PhoneFilter/components/PhoneSegmentBuilder'
import { areEqual, formatDuration } from './utils'

const toSeconds = (minutes: number): number => 60 * minutes

/**
 * Duration Range list
 */
const durations: Range[] = [
  { min: undefined, max: toSeconds(1) },
  { min: toSeconds(1), max: undefined },
  { min: toSeconds(5), max: undefined },
  { min: toSeconds(10), max: undefined },
]

const isPredefinedDuration = (value?: Range): boolean =>
  value !== undefined && durations.some((x) => areEqual(x, value))

type DurationSelectOption = SelectOption & {
  duration: Range
  isCustomPeriod?: boolean
}

const createOption = (
  d: Range,
  isCustomPeriod = false,
): DurationSelectOption => {
  const val = formatDuration(d)
  return {
    label: isCustomPeriod ? t`Custom: ${val}` : val,
    value: (isCustomPeriod ? 'custom:' : '') + [d.min, d.max].join('::'),
    duration: d,
    isCustomPeriod,
  }
}

enum Operator {
  LESS_THAN = '<',
  LESS_THEN_MORE_THAN = '<>',
  MORE_THAN = '>',
}

const createOperatorOptions = (): SelectOption[] => [
  {
    value: Operator.MORE_THAN,
    label: t`Minimum`,
  },
  {
    value: Operator.LESS_THEN_MORE_THAN,
    label: t`Between`,
  },
  {
    value: Operator.LESS_THAN,
    label: t`Maximum`,
  },
]

type DurationSelectProps = FilterCriteriaComponentBaseProps<Range>

export function DurationSelect({
  value: valueProp,
  setValue: setValueProp,
  resetValue,
  onClose,
}: DurationSelectProps): React.ReactElement {
  const [value, setValue] = useState(valueProp ?? undefined)
  const hasValue = value !== undefined
  const valueIsPredefined = isPredefinedDuration(value)
  const [showCustomInput, setShowCustomInput] = useState(
    hasValue && !valueIsPredefined,
  )

  const { selectValue, selectOptions } = React.useMemo(() => {
    const currentValue = value
      ? createOption(value, showCustomInput)
      : undefined
    const options = durations.map((d) => createOption(d, false))
    if (value && (!valueIsPredefined || showCustomInput)) {
      options.push(createOption(value, true))
    } else {
      options.push({
        label: t`Custom duration ...`,
        value: 'custom',
        duration: {},
        isCustomPeriod: true,
      })
    }
    return {
      selectValue: currentValue,
      selectOptions: options,
    }
  }, [value, valueIsPredefined, showCustomInput])

  const applyValue = (duration: Range | undefined): void => {
    if (duration?.min === undefined && duration?.max === undefined) {
      resetValue?.()
    } else {
      setValueProp?.(duration)
    }
    onClose?.()
  }

  const handleChange = (option: OnChangeValue<SelectOption, boolean>): void => {
    const durationOption = option as DurationSelectOption
    if (durationOption.isCustomPeriod && durationOption.value === 'custom') {
      setShowCustomInput(true)
    } else {
      applyValue(durationOption.duration)
    }
  }

  return (
    <Box>
      <FilterCriteriaSelect
        options={selectOptions}
        value={selectValue}
        onChange={handleChange}
        autoFocus={true}
      />
      {showCustomInput && (
        <>
          <Divider />
          <Box px={3} py={1}>
            <Stack spacing={4} direction="row">
              <CustomInput initialValue={value} onChange={setValue} />
              <Flex flex={1} justify="flex-end">
                <Button primary onClick={() => applyValue(value)}>
                  <Trans>OK</Trans>
                </Button>
              </Flex>
            </Stack>
          </Box>
        </>
      )}
    </Box>
  )
}

const CustomInput: React.FC<{
  initialValue?: Range
  onChange: (val: Range) => void
}> = ({ initialValue, onChange }) => {
  const [minSecounds, setMinSecounds] = useState(
    () => initialValue?.min ?? initialValue?.max ?? 60,
  )
  const [maxSecounds, setMaxSecounds] = useState(
    () => initialValue?.max ?? initialValue?.min ?? 60,
  )
  const [operator, setOperator] = useState(() => {
    if (initialValue?.max != null && initialValue?.min != null)
      return Operator.LESS_THEN_MORE_THAN
    if (initialValue?.max != null) return Operator.LESS_THAN
    return Operator.MORE_THAN
  })

  const operatorOptions = createOperatorOptions()

  useEffect(() => {
    switch (operator) {
      case Operator.MORE_THAN:
        onChange({
          min: minSecounds,
          max: undefined,
        })
        break
      case Operator.LESS_THEN_MORE_THAN:
        onChange({
          min: minSecounds,
          max: maxSecounds,
        })
        break

      default:
        onChange({
          min: undefined,
          max: minSecounds,
        })
        break
    }
  }, [minSecounds, maxSecounds, operator, onChange])

  return (
    <Stack spacing={4} direction="row">
      <Box minW="8em">
        <Select
          value={operatorOptions.find((x) => x.value === operator)}
          options={operatorOptions}
          onChange={(option: OnChangeValue<SelectOption, false>) => {
            if (option == null) return
            setMaxSecounds(minSecounds)
            setOperator(option.value as Operator)
          }}
        />
      </Box>
      <Box>
        <Stack spacing={2} direction="row" maxW="12em">
          <DurationInput
            value={minSecounds}
            onChange={(val) => {
              if (operator === Operator.LESS_THEN_MORE_THAN) {
                if (val <= maxSecounds) setMinSecounds(val)
              } else {
                setMinSecounds(val)
              }
            }}
            step={30}
            min={0}
            size="sm"
          />
          {operator === Operator.LESS_THEN_MORE_THAN && (
            <DurationInput
              value={maxSecounds}
              onChange={(val) =>
                minSecounds <= val ? setMaxSecounds(val) : maxSecounds
              }
              step={30}
              min={0}
              size="sm"
            />
          )}
        </Stack>
      </Box>
    </Stack>
  )
}
