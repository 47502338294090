import {
  UpdateTrackerPayload,
  useMoveToFolder,
  useTrackerByUid,
  useTrackerFolders,
  useTrackers,
} from '@capturi/api-trackers'
import { useCurrentUser } from '@capturi/core'
import {
  FilterPeriodSelectContainer,
  MultiSegmentContainer,
  useFilterDefinitions,
  useFilterPeriodContext,
} from '@capturi/filters'
import { DefaultFallbackComponent, usePageTitle } from '@capturi/react-utils'
import { PageHeading, Spinner, useToast } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Box, Flex } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useCallback, useEffect, useState } from 'react'
import { Outlet } from 'react-router'

import TeamFilterRequiredMessage from '../shared/components/TeamFilterRequiredMessage'
import { ConfirmDeleteTrackerFolder } from './components/ConfirmDeleteTrackerFolder'
import EmptyPlaceholder from './components/EmptyPlaceholder'
import { CreateUpdateTrackerFolderDialog } from './components/TrackerFolderForm'
import TrackerList from './components/TrackerList'
import { POLL_INTERVAL } from './constants'
import { Event, logEvent } from './events'
import { SearchTermProvider } from './hooks/useSearchTermContext'
import { TagsProvider } from './hooks/useTagsContext'
import { TrackerProvider } from './hooks/useTrackerContext'
import { useMapListItems } from './useMapListItems'
import { useSegmentOverview } from './useSegmentsOverview'
import { isProcessingInCurrentPeriod } from './utils'

export const TrackersPage: React.FC = () => {
  usePageTitle(t`Trackers`)

  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)
  const toast = useToast()

  const [shouldPollForUpdates, setShouldPollForUpdates] = useState(false)

  const { data: allFolders, error: trackerFoldersError } = useTrackerFolders()
  const {
    data: trackers,
    isLoading: isTrackersLoading,
    error: trackersError,
  } = useTrackers({ refetchInterval: shouldPollForUpdates ? POLL_INTERVAL : 0 })
  const trackersByUid = useTrackerByUid(trackers)

  const { mutate: moveTrackerToFolder } = useMoveToFolder()

  const {
    getSegments,
    isLoading: isSegmentLoading,
    segments,
    revalidate: revalidateSegments,
  } = useSegmentOverview(shouldPollForUpdates)

  const { period } = useFilterPeriodContext()

  useEffect(() => {
    const processingTrackersInCurrentPeriod = trackers?.some((x) =>
      isProcessingInCurrentPeriod({
        isProcessing: x.isProcessing,
        processingProgress: x.processingProgress,
        isTextProcessing: x.isTextProcessing,
        textProcessingProgress: x.textProcessingProgress,
        periodFrom: period.from,
      }),
    )

    if (processingTrackersInCurrentPeriod && !shouldPollForUpdates) {
      setShouldPollForUpdates(true)
    }
    if (!processingTrackersInCurrentPeriod && shouldPollForUpdates) {
      // Tracker is no longer dirty, and polling should stop
      setShouldPollForUpdates(false)
      // We are going to need to update data one last time after the tracker is no longer dirty.
      revalidateSegments()
    }
  }, [trackers, shouldPollForUpdates, revalidateSegments, period.from])

  //Segment errors doesn't really matter, the percentage will just not be shown
  const error = trackersError || trackerFoldersError || null

  const { folders, root, trackerUidsByFolderUids } = useMapListItems(
    trackers,
    allFolders || [],
    isSegmentLoading,
    segments,
    getSegments,
  )

  const handleMoveTrackerToFolder = useCallback(
    (trackerUid: string, folderUid: string | null) => {
      const tracker = trackersByUid?.[trackerUid]

      const updatedFields: UpdateTrackerPayload = {
        folderUid,
        permissionPreset: folderUid ? 'Inherit' : 'Private',
        permissionPresetUserUid: folderUid ? null : currentUser.id,
        acl: null,
      }
      if (tracker)
        moveTrackerToFolder(
          {
            uid: tracker.uid,
            fields: updatedFields,
          },
          {
            onError: () =>
              toast({
                status: 'error',
                title: t`Could not move tracker`,
                description: error instanceof Error ? error.message : undefined,
              }),
            onSuccess: () =>
              toast({
                status: 'success',
                title: t`Successfully moved "${tracker?.name}"`,
              }),
          },
        )
      logEvent(Event.TrackerAnalysis_MovedToFolder)
    },
    [trackersByUid, currentUser.id, moveTrackerToFolder, toast, error],
  )
  const [openDeleteFolder] = useModal(ConfirmDeleteTrackerFolder)
  const [openCreateTrackerFolderDialog] = useModal(
    CreateUpdateTrackerFolderDialog,
  )
  const handleDeleteFolder = useCallback(
    (folderUid: string, folderName: string): void => {
      openDeleteFolder({ folderName: folderName, folderUid: folderUid })
    },
    [openDeleteFolder],
  )

  //TODO: make something nice
  if (isTrackersLoading) {
    return (
      <Flex width="100%" mt="20%" justifyContent="center">
        <Spinner size="xl" />
      </Flex>
    )
  }

  if (!isTrackersLoading && trackers?.length === 0) {
    if (currentUser.isTeamLead) return <TeamFilterRequiredMessage />
    return (
      <EmptyPlaceholder
        canEditTracker={currentUser.permissions.editTracker}
        isAdminOrTeamLead={currentUser.isAdminOrTeamLead}
      />
    )
  }

  return (
    <Box>
      <Flex align="start" justify="space-between">
        <PageHeading>
          <Trans>Trackers</Trans>
        </PageHeading>
        <FilterPeriodSelectContainer />
      </Flex>
      <Box my={8} mb={4}>
        <MultiSegmentContainer
          disabledChannels={[]}
          filterDefinitions={filterDefinitions}
        />
      </Box>
      {error ? (
        <DefaultFallbackComponent error={error} />
      ) : (
        <TrackerProvider>
          <TagsProvider>
            <SearchTermProvider>
              <Flex>
                <TrackerList
                  folders={folders}
                  root={root}
                  trackerUidsByFolderUids={trackerUidsByFolderUids}
                  onMoveTrackerToFolder={handleMoveTrackerToFolder}
                  onDeleteFolder={handleDeleteFolder}
                  onCreateEditFolder={openCreateTrackerFolderDialog}
                />
                <Outlet />
              </Flex>
            </SearchTermProvider>
          </TagsProvider>
        </TrackerProvider>
      )}
    </Box>
  )
}

export default TrackersPage
