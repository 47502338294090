import LogoText_colored from '@capturi/assets/images/logo/capturi_w-text_colored.svg'
import { useModal } from '@capturi/use-modal'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  BoxProps,
  Flex,
  HStack,
  Heading,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Trans, t } from '@lingui/macro'
import { ButtonLink } from 'components/Link'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router'

import { EmailLogin } from './EmailLogin'
import { LanguagesList } from './LanguagesList'
import { NoAccessModal } from './NoAccessModal'
import { ProviderButtons } from './ProviderButtons'
import Waves from './images/WavesGradientLongAnimated.svg'
import EqualizerImage from './images/equalizer.svg'

const LoginPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const message = searchParams.get('message') ?? ''
  const [error, setError] = useState<string | null>(null)
  const [knownOrg, setKnownOrg] = useState<boolean>(true)

  const [openNoAccessModal] = useModal(NoAccessModal)

  useEffect(() => {
    if (message.toLowerCase() === 'no invite code') {
      openNoAccessModal()
    } else {
      setError(message)
    }
  }, [message, openNoAccessModal])

  return (
    <Flex
      h="100%"
      direction="row-reverse"
      overflowY="scroll"
      css={css`
        &::-webkit-scrollbar {
          display: none;
        }
      `}
    >
      <EqualizerArea flex={1} minH="650px" />
      <LoginArea
        w={{ base: '100%', md: '48em' }}
        minH="650px"
        h="100%"
        as="main"
      >
        <Flex align="center" direction="column" w="100%" mt="8vh">
          <Box
            flex="0 0 auto"
            filter="drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.10)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.09)) drop-shadow(0px 5px 3px rgba(0, 0, 0, 0.05)) drop-shadow(0px 10px 4px rgba(0, 0, 0, 0.01)) drop-shadow(0px 15px 4px rgba(0, 0, 0, 0.00))"
          >
            <LogoText_colored width="200px" color="gray.800" />
          </Box>
          <LoginCard w="100%" maxW="30em" mt="6vh">
            <Heading textAlign="center" fontSize="2xl">
              <Trans>Log in to your organization</Trans>
            </Heading>

            <ProviderButtons mt={8} knownOrg={knownOrg} />
            <EmailLogin setKnownOrg={setKnownOrg} />
            {error && (
              <Alert status="error" mt={4}>
                <AlertIcon />
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
          </LoginCard>
        </Flex>
        <Flex
          pos="absolute"
          bottom={0}
          mb={4}
          wrap="nowrap"
          w="100%"
          paddingInlineEnd={12}
        >
          <LanguagesList />
          <Spacer />
          <HStack gap={1}>
            <Text fontSize="sm">
              <Trans>Not a customer yet? Contact us</Trans>
            </Text>
            <ButtonLink
              minW="auto"
              marginInlineStart="0px !important"
              textDecoration="underline"
              color="gray.800"
              variant="link"
              to={t`https://en.capturi.com/get-started`}
            >
              <Trans>here</Trans>
            </ButtonLink>
          </HStack>
        </Flex>
      </LoginArea>
    </Flex>
  )
}

const LoginCard: React.FC<BoxProps> = (props) => {
  return (
    <Box
      mt={4}
      borderRadius={8}
      bg="white"
      boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
      px={6}
      pt={8}
      pb={8}
      {...props}
    />
  )
}

const LoginArea: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Flex
      bg="gray.50"
      pos="relative"
      px={[2, 4, 8]}
      pb={8}
      boxShadow="0px 500px 379px rgba(0, 0, 0, 0.26), 0px 208px 158px rgba(0, 0, 0, 0.18), 0px 111px 84px rgba(0, 0, 0, 0.15), 0px 62px 47px rgba(0, 0, 0, 0.13), 0px 33px 25px rgba(0, 0, 0, 0.10), 0px 13px 10px rgba(0, 0, 0, 0.07)"
      justify="center"
      {...props}
    >
      <Box w="100%" zIndex={1}>
        {children}
      </Box>
      <Box pos="absolute" bottom={0} left={0} right={0} aria-hidden>
        <Waves width="100%" />
      </Box>
    </Flex>
  )
}

const EqualizerArea: React.FC<BoxProps> = (props) => {
  return (
    <Box
      bg="workspace.sidebar.darkbg"
      overflow="hidden"
      pos="relative"
      aria-hidden
      {...props}
    >
      <Box pos="absolute" bottom={0} left={0} height="80%">
        <EqualizerImage height="100%" />
      </Box>
    </Box>
  )
}

export default LoginPage
