import { RepeatCall } from '@capturi/api-filters'
import { useOrganizationSettings } from '@capturi/api-organization'
import { useSegmentStatesContext } from '@capturi/filters'

export type RepeatCallFilterSettings = {
  type: RepeatCall['type'] | undefined
  minDistance: number | null
  maxDistance: number
}

export const useRepeatCallFilterSettings = ():
  | RepeatCallFilterSettings
  | undefined => {
  const { data: organizationSettings } = useOrganizationSettings()
  const { phoneSegmentStates } = useSegmentStatesContext()
  if (phoneSegmentStates.length === 0) return undefined

  const { value, type } = phoneSegmentStates[0].values.repeatCalls[0] || {}

  if (!organizationSettings) return undefined

  return {
    type: type ?? undefined,
    minDistance: organizationSettings.repeatCall.minDistance,
    maxDistance: value ?? organizationSettings.repeatCall.maxDistance,
  }
}
