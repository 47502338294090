import {
  PhoneFilterValues,
  SavedPhoneFilter,
  SavedTextFilter,
  TextFilterValues,
  useSavedFilter,
  useSavedTextFilter,
} from '@capturi/api-filters'
import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import {
  Channel,
  SegmentState,
  SegmentStatesProviderProps,
  parsePhoneFilterValuesJSON,
  parseTextFilterValuesJSON,
  sanitizeFilter,
  toFilterValues,
  toTextFilterValues,
} from '@capturi/filters'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import React, { useCallback, useMemo, useRef } from 'react'
import isEqual from 'react-fast-compare'
import { createSearchParams, useSearchParams } from 'react-router'

const SAVED_SUB_FILTER_UID_PARAM_NAME = 'savedSubFilterUid'
const SAVED_FILTER_UID_PARAM_NAME = 'savedFilterUid'
const CHANNEL_PARAM_NAME = 'channel'

const useCreatePhoneFilter = (
  filterValuesParam: string | null,
  filterUidParam: string | null,
): {
  state: {
    savedFilter?: SavedPhoneFilter
    values?: Partial<PhoneFilterValues>
  }
  isLoading: boolean
} => {
  const valuesFilterParam = useRef(filterValuesParam).current
  const savedFilterParam = useRef(filterUidParam).current

  // Load saved filter from filterUidParam URL param
  const { data: savedFilter, isInitialLoading } = useSavedFilter({
    uid: savedFilterParam,
    options: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Number.POSITIVE_INFINITY,
    },
  })
  return useMemo(() => {
    const savedFilterValues = savedFilter?.values
      ? toFilterValues(savedFilter.values)
      : {}
    const customFilterValues = parsePhoneFilterValuesJSON(valuesFilterParam)
    return {
      state: {
        values: merge({}, savedFilterValues, customFilterValues),
        savedFilter: savedFilter,
      },
      isLoading: isInitialLoading,
    }
  }, [isInitialLoading, savedFilter, valuesFilterParam])
}

const useCreateTextFilter = (
  filterValuesParam: string | null,
  filterUidParam: string | null,
): {
  state: {
    savedFilter?: SavedTextFilter
    values?: Partial<TextFilterValues>
  }
  isLoading: boolean
} => {
  const textFilterValuesParam = useRef(filterValuesParam).current
  const savedTextFilterParam = useRef(filterUidParam).current

  const { data: savedFilter, isInitialLoading } = useSavedTextFilter({
    uid: savedTextFilterParam,
    options: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Number.POSITIVE_INFINITY,
    },
  })

  return useMemo(() => {
    const customFilterValues = parseTextFilterValuesJSON(textFilterValuesParam)
    const savedFilterValues = savedFilter
      ? toTextFilterValues(savedFilter.values)
      : undefined
    return {
      state: {
        values: merge({}, savedFilterValues, customFilterValues),
        savedFilter: savedFilter,
      },
      isLoading: isInitialLoading,
    }
  }, [textFilterValuesParam, savedFilter, isInitialLoading])
}

function updateURLSearchParams(
  states: SegmentState[],
  oldSearchParams: URLSearchParams,
): URLSearchParams {
  const newParams = createSearchParams(oldSearchParams)

  if (states.length === 0) {
    newParams.delete(CHANNEL_PARAM_NAME)
    newParams.delete(SAVED_FILTER_UID_PARAM_NAME)
    newParams.delete(SAVED_SUB_FILTER_UID_PARAM_NAME)
    newParams.delete('filter')
    newParams.delete('subFilter')
    return newParams
  }

  const currentWindowLocationParams = new URLSearchParams(
    window.location.search,
  )
  const currentSavedFilterValue = currentWindowLocationParams.get(
    SAVED_FILTER_UID_PARAM_NAME,
  )
  const currentSavedSubFilterValue = currentWindowLocationParams.get(
    SAVED_SUB_FILTER_UID_PARAM_NAME,
  )
  const currentChannelValue =
    currentWindowLocationParams.get(CHANNEL_PARAM_NAME)

  const newSavedFilterUid =
    states[0].channel === 'phone'
      ? states[0].savedFilter?.uid
      : states[0].savedTextFilter?.uid
  const newSavedSubFilterUid =
    states[0].channel === 'phone'
      ? states[0].subFilterState?.savedFilter?.uid
      : states[0].subFilterState?.savedTextFilter?.uid

  // Set savedFilterUid (if selected), otherwise set custom filter values
  if (newSavedFilterUid) {
    if (currentSavedFilterValue !== newSavedFilterUid) {
      newParams.set(SAVED_FILTER_UID_PARAM_NAME, newSavedFilterUid ?? '')
      newParams.delete('filter') // when saved filter is selected, we only keep that in URL, discard custom values
    }
  } else {
    newParams.delete(SAVED_FILTER_UID_PARAM_NAME)
    const sanitizedFilter = sanitizeFilter(states[0].values)
    if (sanitizedFilter == null || isEmpty(sanitizedFilter)) {
      newParams.delete('filter')
    } else {
      newParams.set('filter', JSON.stringify(sanitizedFilter))
    }
  }

  // Set savedSubFilterUid (if selected), otherwise set custom subFilter values
  if (newSavedSubFilterUid) {
    if (currentSavedSubFilterValue !== newSavedSubFilterUid) {
      newParams.set(SAVED_SUB_FILTER_UID_PARAM_NAME, newSavedSubFilterUid ?? '')
      newParams.delete('subFilter') // when saved subfilter is selected, we only keep that in URL, discard custom values
    }
  } else {
    newParams.delete(SAVED_SUB_FILTER_UID_PARAM_NAME)
    const sanitizedSubFilter = sanitizeFilter(
      states[0].subFilterState?.values ?? {},
    )
    if (sanitizedSubFilter == null || isEmpty(sanitizedSubFilter)) {
      newParams.delete('subFilter')
    } else {
      newParams.set('subFilter', JSON.stringify(sanitizedSubFilter))
    }
  }

  if (states[0].channel !== currentChannelValue) {
    newParams.set(CHANNEL_PARAM_NAME, states[0].channel)
  }

  return newParams
}

export function withSavedFilterUrlSync<T extends SegmentStatesProviderProps>(
  WrappedComponent: React.ComponentType<T>,
): React.FC<T> {
  return (props: T) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { useEmailChannelAsDefault } = useFeatureFlags()

    const channel: Channel =
      (searchParams.get(CHANNEL_PARAM_NAME) as Channel) ??
      (useEmailChannelAsDefault ? 'email' : 'phone')

    const { isLoading: isFilterLoading, ...filterState } = useCreatePhoneFilter(
      channel === 'phone' ? searchParams.get('filter') : null,
      channel === 'phone'
        ? searchParams.get(SAVED_FILTER_UID_PARAM_NAME)
        : null,
    )

    const { isLoading: isSubFilterLoading, ...subfilterState } =
      useCreatePhoneFilter(
        channel === 'phone' ? searchParams.get('subFilter') : null,
        channel === 'phone'
          ? searchParams.get(SAVED_SUB_FILTER_UID_PARAM_NAME)
          : null,
      )

    const { isLoading: isTextFilterLoading, ...textFilterState } =
      useCreateTextFilter(
        channel === 'email' ? searchParams.get('filter') : null,
        channel === 'email'
          ? searchParams.get(SAVED_FILTER_UID_PARAM_NAME)
          : null,
      )

    const { isLoading: isTextSubFilterLoading, ...textSubfilterState } =
      useCreateTextFilter(
        channel === 'email' ? searchParams.get('subFilter') : null,
        channel === 'email'
          ? searchParams.get(SAVED_SUB_FILTER_UID_PARAM_NAME)
          : null,
      )

    const initialState: T['initialState'] = useMemo(() => {
      if (channel === 'email') {
        return {
          state: { ...textFilterState.state },
          subfilterState: { ...textSubfilterState.state },
          channel: 'email',
        }
      }
      return {
        state: { ...filterState.state },
        subfilterState: { ...subfilterState.state },
        channel: 'phone',
      }
    }, [
      channel,
      filterState.state,
      subfilterState.state,
      textFilterState.state,
      textSubfilterState.state,
    ])

    const handleStatesChange = useCallback(
      (states: SegmentState[]): void => {
        const newParams = updateURLSearchParams(states, searchParams)
        if (!isEqual(newParams, searchParams)) {
          setSearchParams(newParams, { replace: true })
        }
        props.onStatesChange?.(states)
      },
      [props, searchParams, setSearchParams],
    )

    // Wait until first set of filters is loaded before rendering
    const initialPhoneLoadCompleted = useRef(false)
    const initialTextLoadCompleted = useRef(false)
    const phoneFiltersAreLoading = isFilterLoading || isSubFilterLoading
    const textFiltersAreLoading = isTextFilterLoading || isTextSubFilterLoading
    // Update refs once the initial load of each filter type is completed
    if (!(phoneFiltersAreLoading || initialPhoneLoadCompleted.current)) {
      initialPhoneLoadCompleted.current = true
    }
    if (!(textFiltersAreLoading || initialTextLoadCompleted.current)) {
      initialTextLoadCompleted.current = true
    }
    // Render null only during the initial load of both sets of filters
    if (
      (phoneFiltersAreLoading && !initialPhoneLoadCompleted.current) ||
      (textFiltersAreLoading && !initialTextLoadCompleted.current)
    ) {
      return null
    }

    return (
      <WrappedComponent
        {...props}
        initialState={initialState}
        onStatesChange={handleStatesChange}
      />
    )
  }
}

export function withInitialValuesFromRole<T extends SegmentStatesProviderProps>(
  WrappedComponent: React.ComponentType<T>,
): React.FC<T> {
  return (props: T) => {
    const user = useCurrentUser()
    const { useEmailChannelAsDefault } = useFeatureFlags()

    const teamUids = user.teamLeadTeamUids // little help to TypeScript
    if (!user.isTeamLead || teamUids == null) {
      // Not a team lead, or teamUids are empty, return the component as is
      return <WrappedComponent {...props} />
    }

    if (!props.initialState) {
      // props did not have initialState, existing with simple default
      const initialState: T['initialState'] = useEmailChannelAsDefault
        ? {
            channel: 'email',
            state: {
              values: {
                teamFilters: [{ values: teamUids, inverted: false }],
              },
            },
          }
        : { channel: 'phone', state: { values: { teamUids } } }
      return <WrappedComponent initialState={initialState} {...props} />
    }

    // embedding team filter into initialState
    const modifiedInitialState = { ...props.initialState }
    if (modifiedInitialState.channel === 'phone') {
      modifiedInitialState.state = {
        ...modifiedInitialState.state,
        values: {
          ...modifiedInitialState.state?.values,
          teamUids,
        },
      }
    } else {
      modifiedInitialState.state = {
        ...modifiedInitialState.state,
        values: {
          ...modifiedInitialState.state?.values,
          teamFilters: [{ values: teamUids, inverted: false }],
        },
      }
    }

    // Spread the rest of the props and override initialState
    return <WrappedComponent {...props} initialState={modifiedInitialState} />
  }
}
