import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  Stack,
  StackProps,
  Text,
} from '@chakra-ui/react'
import noop from 'lodash/noop'
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react'
import {
  MdForward5,
  MdForward10,
  MdForward30,
  MdPause,
  MdPlayArrow,
  MdReplay5,
  MdReplay10,
  MdReplay30,
} from 'react-icons/md'
import { usePrevious, useSlider } from 'react-use'

import {
  AudioOptions,
  UseAudioPlayback,
  useAudioContext,
} from '../AudioProvider'
import useAudioTime from '../useAudioTime'
import { formatTime } from '../utils'

type JumpSeconds = 5 | 10 | 30

const jumpForwardIcon = (jumpSeconds: JumpSeconds): React.ComponentType => {
  switch (jumpSeconds) {
    case 5:
      return MdForward5
    case 10:
      return MdForward10
    case 30:
      return MdForward30
  }
}

const jumpBackwardIcon = (jumpSeconds: JumpSeconds): React.ComponentType => {
  switch (jumpSeconds) {
    case 5:
      return MdReplay5
    case 10:
      return MdReplay10
    case 30:
      return MdReplay30
  }
}

type SeekState = {
  isSeeking: boolean
  seekValue?: number
}

type SeekAction =
  | { type: 'start'; value: number }
  | { type: 'seek'; value: number }
  | { type: 'stop' }

function seekReducer(_state: SeekState, action: SeekAction): SeekState {
  switch (action.type) {
    case 'start':
      return {
        isSeeking: true,
        seekValue: action.value,
      }
    case 'seek':
      return {
        isSeeking: true,
        seekValue: action.value,
      }
    case 'stop':
      return {
        isSeeking: false,
        seekValue: undefined,
      }
  }
}

const defaultSeekStateValue: SeekState = {
  isSeeking: false,
  seekValue: undefined,
}

const SeekStateContext = React.createContext(defaultSeekStateValue)
const SeekUpdaterContext = React.createContext<React.Dispatch<SeekAction>>(noop)

type AudioPlaybackContextType = Pick<
  UseAudioPlayback,
  | 'getTime'
  | 'isPlaying'
  | 'pause'
  | 'play'
  | 'playbackRate'
  | 'seek'
  | 'setPlaybackRate'
  | 'stop'
  | 'toggle'
>

const AudioPlaybackContext = React.createContext<AudioPlaybackContextType>({
  getTime: () => 0,
  isPlaying: false,
  pause: noop,
  play: Promise.reject,
  playbackRate: 1,
  seek: Promise.reject,
  setPlaybackRate: noop,
  stop: noop,
  toggle: () => false,
})

export const Provider: React.FC<{
  options: AudioOptions
  audioSource: string
  children?: React.ReactNode
}> = ({ children, options = {}, audioSource }) => {
  const [state, dispatch] = useReducer(seekReducer, defaultSeekStateValue)
  const audioContext = useAudioContext(audioSource, options)
  return (
    <SeekUpdaterContext.Provider value={dispatch}>
      <SeekStateContext.Provider value={state}>
        <AudioPlaybackContext.Provider value={audioContext}>
          <Flex align="center">{children}</Flex>
        </AudioPlaybackContext.Provider>
      </SeekStateContext.Provider>
    </SeekUpdaterContext.Provider>
  )
}

export const ButtonGroup: React.FC<StackProps> = ({
  spacing = 2,
  ...props
}) => {
  return <Stack direction="row" spacing={spacing} flex="0 0 auto" {...props} />
}

export const IconButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      rounded="full"
      size="xs"
      variant="ghost"
      p={0}
      fontSize="lg"
      _hover={{ backgroundColor: 'gray.300' }}
      _active={{ backgroundColor: 'gray.400' }}
      {...props}
    />
  )
}

const wrapEvent = (
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  userEvent?: (event: React.MouseEvent<any, MouseEvent>) => void,
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  proxyEvent?: (event: React.MouseEvent<any, MouseEvent>) => void,
) => {
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  return (event: React.MouseEvent<any, MouseEvent>) => {
    try {
      proxyEvent?.(event)
    } finally {
      userEvent?.(event)
    }
  }
}

export const PlayPauseButton: React.FC<
  Omit<ButtonProps, 'children' | 'onPlay' | 'onPause'> & {
    onPlay?: () => void
    onPause?: () => void
  }
> = ({ onClick: onClickProp, onPlay, onPause, ...props }) => {
  const { play, pause, isPlaying } = React.useContext(AudioPlaybackContext)
  const onTogglePlay = (): void => {
    if (isPlaying) {
      pause()
      onPause?.()
    } else {
      play()
      onPlay?.()
    }
  }

  return (
    <IconButton onClick={wrapEvent(onClickProp, onTogglePlay)} {...props}>
      {isPlaying ? <MdPause /> : <MdPlayArrow />}
    </IconButton>
  )
}

type JumpButtonProps = {
  jumpSeconds?: JumpSeconds
} & Omit<ButtonProps, 'children'>

export const JumpBackwardButton: React.FC<JumpButtonProps> = ({
  jumpSeconds = 10,
  onClick: onClickProp,
  ...props
}) => {
  const { seek, getTime } = React.useContext(AudioPlaybackContext)

  const jumpBackward = (): void => {
    const timestamp = getTime() - jumpSeconds
    seek(timestamp)
  }

  const JumpBackwardIcon = jumpBackwardIcon(jumpSeconds)
  return (
    <IconButton onClick={wrapEvent(onClickProp, jumpBackward)} {...props}>
      <JumpBackwardIcon />
    </IconButton>
  )
}

export const JumpForwardButton: React.FC<JumpButtonProps> = ({
  jumpSeconds = 10,
  onClick: onClickProp,
  ...props
}) => {
  const { seek, getTime } = React.useContext(AudioPlaybackContext)

  const jumpForward = (): void => {
    const timestamp = getTime() + jumpSeconds
    seek(timestamp)
  }

  const JumpForwardIcon = jumpForwardIcon(jumpSeconds)
  return (
    <IconButton onClick={wrapEvent(onClickProp, jumpForward)} {...props}>
      <JumpForwardIcon />
    </IconButton>
  )
}

export const PlaybackRateButton: React.FC<Omit<ButtonProps, 'children'>> = ({
  onClick: onClickProp,
  ...props
}) => {
  const { playbackRate, setPlaybackRate } =
    React.useContext(AudioPlaybackContext)

  const toggleSpeed = (): void => {
    setPlaybackRate((currentRate) => {
      let newRate = 1
      if (currentRate < 2) {
        newRate = currentRate + 0.25
      }
      return newRate
    })
  }

  return (
    <IconButton
      onClick={wrapEvent(onClickProp, toggleSpeed)}
      fontSize="sm"
      {...props}
    >
      {playbackRate}x
    </IconButton>
  )
}

type TimeProps = {
  showDuration?: boolean
} & BoxProps

export const Time: React.FC<TimeProps> = ({
  showDuration = false,
  ...props
}) => {
  const { isSeeking, seekValue } = useContext(SeekStateContext)
  const [time = 0, duration] = useAudioTime()

  const timeOrSeek = useMemo(() => {
    if (isSeeking && seekValue !== undefined) {
      return seekValue * duration
    }
    return time
  }, [isSeeking, seekValue, time, duration])

  return (
    <Text {...props}>
      <Text as="span">{formatTime(timeOrSeek)}</Text>
      {showDuration && <Text as="span"> / {formatTime(duration)}</Text>}
    </Text>
  )
}

type TrackProps = {
  onSeekStop?: () => void
} & BoxProps

export const Track: React.FC<TrackProps> = ({ onSeekStop, ...props }) => {
  const seekDispatch = useContext(SeekUpdaterContext)
  const { seek } = React.useContext(AudioPlaybackContext)
  const [time = 0, duration] = useAudioTime()

  const sliderRef = useRef(null)
  const { isSliding, value: sliderValue } = useSlider(sliderRef)
  const wasSliding = usePrevious(isSliding)

  const seekerValue = React.useMemo(() => {
    if (isSliding) {
      return sliderValue
    }
    return time / duration || 0
  }, [isSliding, sliderValue, time, duration])

  useEffect(() => {
    if (wasSliding && !isSliding) {
      const timestamp = sliderValue * duration
      seek(timestamp)
      onSeekStop?.()
    }
  }, [wasSliding, isSliding, sliderValue, duration, seek, onSeekStop])

  useEffect(() => {
    if (!wasSliding && isSliding) {
      seekDispatch({ type: 'start', value: sliderValue })
    }
    if (wasSliding && isSliding) {
      seekDispatch({ type: 'seek', value: sliderValue })
    }
    if (wasSliding && !isSliding) {
      seekDispatch({ type: 'stop' })
    }
  }, [seekDispatch, wasSliding, isSliding, sliderValue])

  return (
    <Flex
      ref={sliderRef}
      flex={1}
      h={6}
      align="center"
      pos="relative"
      {...props}
    >
      <Box h="4px" w="full" bg="gray.300" rounded="full" overflow="hidden">
        <Box
          bg="gray.700"
          h="full"
          style={{ width: `${seekerValue * 100}%` }}
        />
      </Box>
      <Box
        w="8px"
        h="8px"
        bg="gray.700"
        rounded="full"
        pos="absolute"
        style={{ left: `${seekerValue * 100}%` }}
        transform="translateX(-50%)"
      />
    </Flex>
  )
}
