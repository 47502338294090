import React, { useCallback, useMemo } from 'react'

type TabsConfig<ValueType> = BaseTab<ValueType>[]

export type BaseTab<ValueType> = {
  value: ValueType
  tab?: React.ReactNode
  show?: boolean
}

export interface UseTabsState<ValueType> {
  tabIndex: number
  tabs: BaseTab<ValueType>[]
  selectedTab: ValueType
  setSelectedTab: (value: ValueType) => void
  getTab: (index: number) => BaseTab<ValueType>
}

export const useTabsState = <ValueType = string>(
  config: TabsConfig<ValueType>,
  initialTab: ValueType,
): UseTabsState<ValueType> => {
  const tabsRef = React.useRef(config)
  const tabs = tabsRef.current

  const getTab = useCallback((index: number) => tabs[index], [tabs])

  const [selectedTab, setSelectedTab] = React.useState<ValueType>(initialTab)
  const tabIndex = React.useMemo(() => {
    return tabs.findIndex((tab) => tab.value === selectedTab)
  }, [tabs, selectedTab])

  return useMemo(
    () => ({
      tabIndex,
      tabs,
      getTab,
      selectedTab,
      setSelectedTab,
    }),
    [getTab, tabIndex, tabs, selectedTab],
  )
}
