import analytics from '@capturi/analytics'
import {
  BaseTracker,
  MessageFilters,
  MessagePosition,
  TrackerFormModel,
  useCreateTracker,
} from '@capturi/api-trackers'
import { useCurrentUser } from '@capturi/core'
import { usePageTitle } from '@capturi/react-utils'
import { ResponseError } from '@capturi/request'
import { useToast } from '@capturi/ui-components'
import { Flex } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { getRoutes } from 'pages/analytics'
import qs from 'query-string'
import {
  type ComponentProps,
  type FC,
  type ReactElement,
  useCallback,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router'

import FormActions from '../components/FormActions'
import { TrackerEditor, TrackerEditorProvider } from '../editor'
import { useLastSelectedTracker } from '../hooks/useLastSelectedTracker'
import { default as trackerActionRoutes } from '../routes'

type LocationState = {
  redirectPath: string | undefined
  initialData: Partial<BaseTracker> | undefined
}

function parseLocationState(arg: unknown): LocationState {
  const result: LocationState = {
    redirectPath: undefined,
    initialData: undefined,
  }
  if (arg == null) {
    return result
  }
  const state = arg as Partial<LocationState>

  if (typeof state.redirectPath === 'string') {
    result.redirectPath = state.redirectPath
  }
  if (typeof state.initialData === 'object') {
    result.initialData = state.initialData
  }
  return result
}

const CreateTrackerPage: FC = () => {
  const location = useLocation()
  const { redirectPath } = parseLocationState(location.state)
  const navigate = useNavigate()
  const { mutate: createTracker } = useCreateTracker()
  const toast = useToast()

  const [isMasterFolder, setIsMasterFolder] = useState<boolean>(false)
  const [hasPhrases, setHasPhrases] = useState<boolean>(false)
  const [hasTextPhrases, setHasTextPhrases] = useState<boolean>(false)
  const [messageFilters, setMessageFilters] = useState<MessageFilters | null>({
    messageTypes: ['Inbound', 'Outbound'],
    messageFields: ['Text'],
    messagePosition: MessagePosition.Any,
  })
  const [masterTrackerPreset, setMasterTrackerPreset] = useState<boolean>(false)
  const { setLastSelectedTracker } = useLastSelectedTracker()

  const closeEditor = useCallback(
    (newTracker?: BaseTracker): void => {
      if (redirectPath) {
        const { url, query } = qs.parseUrl(redirectPath)
        const redirectQuery = {
          ...query,
          ...(newTracker
            ? {
                trackerUid: newTracker.uid,
                speaker: newTracker.speech?.speakerId,
              }
            : {}),
        }
        //If we're on insights tracker page and not settings trackerpage. We navigate differently, if so.
        const insightsQuery = !!redirectPath.includes('insights')
        setLastSelectedTracker(newTracker?.uid)
        navigate(
          qs.stringifyUrl(
            insightsQuery
              ? { url: getRoutes().trackers.main() }
              : {
                  url,
                  query: redirectQuery,
                },
          ),
        )
      } else {
        navigate(trackerActionRoutes.root)
      }
    },
    [navigate, redirectPath, setLastSelectedTracker],
  )

  const onSaveTracker = useCallback(
    (formData: TrackerFormModel): void => {
      createTracker(formData, {
        onError: (error) => {
          if (error instanceof ResponseError) {
            toast({
              title:
                formData.masterSettings?.targets.length === 0
                  ? t`Missing organization for master tracker`
                  : t`An error occurred. Please try again.`,
              description:
                formData.masterSettings?.targets.length === 0
                  ? t`You need to select at least one organization for the master tracker in order to save.`
                  : error.message,
              status: 'error',
            })
          }
        },
        onSuccess: (data) => {
          analytics.event('tracker-editor_TrackerCreated', formData)
          if (redirectPath) {
            toast({
              title: t`"${formData.name}" created`,
              status: 'success',
            })
          }
          closeEditor(data)
        },
      })
    },
    [closeEditor, createTracker, redirectPath, toast],
  )

  return (
    <TrackerEditor
      onSubmit={onSaveTracker}
      onPhrasesListChange={(hasSpeechPhrases, hasTextPhrases) => {
        setHasPhrases(hasSpeechPhrases || hasTextPhrases)
        setHasTextPhrases(hasTextPhrases)
      }}
      onMessageFiltersChange={setMessageFilters}
      onFolderChange={(value) => setIsMasterFolder(value)}
      onMasterTrackerToggle={(value) => setMasterTrackerPreset(value)}
      renderHeaderActions={(
        { isSubmitting },
        hasDuplicatePhrases,
        hasPhraseWithOrphanedQuote,
        isPreviewAccessLoading,
        hasPreviewAccessValidationError,
        importTracker,
        _,
        removeSavedTracker,
      ) => (
        <FormActions
          isLoading={isPreviewAccessLoading}
          isSaving={isSubmitting}
          isSaveDisabled={
            hasPreviewAccessValidationError ||
            hasDuplicatePhrases ||
            hasPhraseWithOrphanedQuote ||
            (masterTrackerPreset && !isMasterFolder) ||
            !hasPhrases ||
            (!(
              messageFilters?.messageFields?.length &&
              messageFilters?.messageTypes?.length
            ) &&
              hasTextPhrases)
          }
          saveTooltip={
            !hasPhrases
              ? t`A minimum of one word/phrase for either phone or text is required to save the tracker.`
              : !messageFilters?.messageTypes?.length && hasTextPhrases
                ? t`Please select at least one type under 'Mentioned in'.`
                : !messageFilters?.messageFields?.length && hasTextPhrases
                  ? t`Please select at least one checkbox under 'Active in'.`
                  : undefined
          }
          onCancel={() => {
            removeSavedTracker?.()
            closeEditor()
          }}
          onImportTracker={importTracker}
        />
      )}
      renderFooterActions={(
        { isSubmitting },
        hasDuplicatePhrases,
        hasPhraseWithOrphanedQuote,
        isPreviewAccessLoading,
        hasPreviewAccessValidationError,
        removeSavedTracker,
      ) => (
        <Flex mb={4} w="100%">
          <FormActions
            isLoading={false}
            isSaving={isSubmitting}
            isSaveDisabled={
              isPreviewAccessLoading ||
              hasPreviewAccessValidationError ||
              hasDuplicatePhrases ||
              hasPhraseWithOrphanedQuote ||
              (masterTrackerPreset && !isMasterFolder) ||
              !hasPhrases ||
              (!(
                messageFilters?.messageFields?.length &&
                messageFilters?.messageTypes?.length
              ) &&
                hasTextPhrases)
            }
            saveTooltip={
              !hasPhrases
                ? t`A minimum of one word/phrase for either phone or text is required to save the tracker.`
                : !messageFilters?.messageTypes?.length && hasTextPhrases
                  ? t`Please select at least one type under 'Mentioned in'.`
                  : !messageFilters?.messageFields?.length && hasTextPhrases
                    ? t`Please select at least one checkbox under 'Active in'.`
                    : undefined
            }
            onCancel={() => {
              removeSavedTracker?.()
              closeEditor()
            }}
          />
        </Flex>
      )}
    />
  )
}

export default function CreateTrackerPageContainer(
  props: ComponentProps<typeof CreateTrackerPage>,
): ReactElement {
  usePageTitle(t`Create tracker`)
  const location = useLocation()
  const { initialData } = parseLocationState(location.state)
  const currentUser = useCurrentUser()
  return (
    <TrackerEditorProvider
      initialValues={(createFormModel) =>
        createFormModel(currentUser.id, initialData)
      }
    >
      <CreateTrackerPage {...props} />
    </TrackerEditorProvider>
  )
}
