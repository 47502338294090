import { sanitizeObject } from '@capturi/dashboard/src/hooks/useWidgetFilterLink'
import { useOrganization, useUsers } from '@capturi/stores'
import { useModal } from '@capturi/use-modal'
import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  IconButton,
  Link,
  Text,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { Subscription } from 'pages/shared/Subscriptions/types'
import qs from 'query-string'
import React, { memo, useMemo } from 'react'
import { MdEdit, MdOpenInNew } from 'react-icons/md'
import SubscriptionModal from '../../HomePage/Subscription/SubscriptionModal'
import SubscriptionFilter from '../../HomePage/Subscription/components/SubscriptionFilter'
import SubscriptionSchedule from './SubscriptionSchedule'

type Props = {
  subscription: Subscription
  label: string
}

const SubscriptionCard: React.FC<Props> = ({ subscription, label }) => {
  const [openSubscriptionModal] = useModal(SubscriptionModal)
  const { uid: organizationUid } = useOrganization()
  const { getUserByUid, users } = useUsers()
  const url = `${location.origin}/home/`
  const shareFilterSearchParams = sanitizeObject(subscription.filter || {})

  const widgetLink = useMemo(() => {
    return qs.stringifyUrl({
      url,
      query: {
        org: organizationUid,
        filter: Object.entries(shareFilterSearchParams ?? {}).length
          ? JSON.stringify(shareFilterSearchParams)
          : undefined,
        channel: 'phone',
      },
    })
  }, [organizationUid, shareFilterSearchParams, url])

  return (
    <Card>
      <CardHeader display="flex">
        <Flex flexDir="column" flex={1} justify="space-between">
          <Text fontWeight="medium" fontSize="medium" mb={2}>
            {subscription.name}
          </Text>
          <SubscriptionFilter filter={subscription.filter} readonly={true} />
        </Flex>
        <HStack align="flex-end">
          <Link href={widgetLink} isExternal>
            <IconButton
              aria-label="Open homepage with filter"
              icon={<MdOpenInNew />}
              variant="ghost"
              _hover={{ backgroundColor: 'none' }}
              _active={{ backgroundColor: 'none' }}
            />
          </Link>
          <IconButton
            aria-label="Edit subscription"
            icon={<MdEdit />}
            onClick={() =>
              openSubscriptionModal({
                subscription: subscription,
                users: users,
              })
            }
          />
        </HStack>
      </CardHeader>
      <CardBody>
        <Flex flexDir="row">
          <Flex flexDir="column" flex="15%">
            <Text color="gray.600" fontSize="sm">
              <Trans>Repeat every</Trans>
            </Text>
            <SubscriptionSchedule schedule={subscription.schedule} />
          </Flex>
          <Flex flex="10%" flexDir="column">
            <Text color="gray.600" fontSize="sm">
              <Trans>Data from the</Trans>
            </Text>
            <Text fontWeight="medium" fontSize="sm">
              {label}
            </Text>
          </Flex>
          <Flex flex="30%" flexDir="column" align="center" justify="end">
            <Text color="gray.600">
              <Trans>Next send out is </Trans>{' '}
              {i18n.date(subscription.next, { dateStyle: 'long' })}
            </Text>
          </Flex>
          <Flex flex="30%" flexDir="column" align="end" justify="end">
            <Text color="gray.600">
              <Trans>Created by </Trans>{' '}
              {getUserByUid(subscription.createdByUserUid).name},{' '}
              {i18n.date(subscription.created, { dateStyle: 'long' })}
            </Text>
            {subscription.updatedByUserUid && (
              <Text color="gray.600">
                <Trans>Updated by</Trans>{' '}
                {getUserByUid(subscription.updatedByUserUid).name},{' '}
                {i18n.date(subscription.updated, { dateStyle: 'long' })}
              </Text>
            )}
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  )
}

export default memo(SubscriptionCard)
