import { ErrorBoundary } from '@capturi/react-utils'
import { Spinner } from '@capturi/ui-components'
import { Provider } from 'jotai'
import React from 'react'
import { Route, Routes } from 'react-router'

export { routes } from './routes'

const ScoreInsightsPage = React.lazy(() => import('./pages/ScoreInsightsPage'))
const CreateScorePage = React.lazy(() => import('./pages/CreateScorePage'))
const EditScorePage = React.lazy(() => import('./pages/EditScorePage'))

const ScoringPageRouter: React.FC = () => {
  return (
    <Provider>
      <ErrorBoundary>
        <React.Suspense fallback={<Spinner display="block" m="5rem auto" />}>
          <Routes>
            <Route path="/" element={<ScoreInsightsPage />} />
            <Route path="/create" element={<CreateScorePage />} />
            <Route path="/edit/:uid" element={<EditScorePage />} />
          </Routes>
        </React.Suspense>
      </ErrorBoundary>
    </Provider>
  )
}

export default ScoringPageRouter
