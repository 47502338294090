import analytics from '@capturi/analytics'
import LogoText_Negative from '@capturi/assets/images/logo/capturi_w-text_xmas.svg'
import { useCurrentUser } from '@capturi/core'
import styled from '@capturi/ui-theme'
import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import ProfileMenu from 'components/ProfileMenu'
import { NotificationCenter } from 'features/notifications'
import noop from 'lodash/noop'
import React, { useMemo } from 'react'
import { AiFillGift } from 'react-icons/ai'
import { MdMenu, MdMenuOpen } from 'react-icons/md'
import { RiShieldFlashFill } from 'react-icons/ri'

import Link from '../../Link'
import MainMenu from './MainMenu'
import OrganizationChooser from './OrganizationChooser'

const ScrollArea = styled.div`
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox 64 */
  -ms-overflow-style: none; /* Internet Explorer 11 */
  &::-webkit-scrollbar {
    /* WebKit */
    display: none;
  }
`

const SIDEBAR_WIDTH = '13rem'

const SidebarContent: React.FC<{
  onClose: () => void
  toggleIsSidebarOpen: () => void
  isSidebarOpened: boolean
  showAsOverlay: boolean
}> = ({ onClose, toggleIsSidebarOpen, isSidebarOpened, showAsOverlay }) => {
  const versionString = useMemo(
    () => `${process.env.APP_VERSION}: ${process.env.BUILD_TIME}`,
    [],
  )

  const { hasSuperPowers, organizationId } = useCurrentUser()
  return (
    <Flex
      direction="column"
      position="relative"
      h="full"
      shadow="xl"
      width={SIDEBAR_WIDTH}
    >
      <Flex
        w="full"
        pt={4}
        pb={10}
        pl={2}
        pr={4}
        borderBottom="1px"
        borderBottomColor="whiteAlpha.300"
        justifyContent="space-between"
        position="relative"
      >
        <Link to="/" display="block" h="100%" onClick={toggleIsSidebarOpen}>
          <Box
            as={LogoText_Negative}
            fill="workspace.sidebar.text"
            h="100%"
            w="150px"
            filter="drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.10)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.09)) drop-shadow(0px 5px 3px rgba(0, 0, 0, 0.05)) drop-shadow(0px 10px 4px rgba(0, 0, 0, 0.01)) drop-shadow(0px 15px 4px rgba(0, 0, 0, 0.00))"
          />
        </Link>

        <IconButton
          isRound
          variant={'solid'}
          position="absolute"
          top={6}
          size="md"
          _hover={{
            bg: 'accents.lightBackground.default',
          }}
          background="accents.lightBackground.default"
          color="gray.800"
          boxShadow="base"
          aria-label={t`Toggle side menu`}
          onClick={toggleIsSidebarOpen}
          icon={isSidebarOpened ? <MdMenuOpen /> : <MdMenu />}
          display="inherit"
          right={isSidebarOpened ? '-15px' : '-25px'}
          transition="200ms ease-in-out"
        />
      </Flex>

      <OrganizationChooser />

      <Box flex={1} overflow="hidden">
        <ScrollArea>
          <MainMenu onClose={showAsOverlay ? onClose : noop} mt={0} />
        </ScrollArea>
      </Box>
      <Flex
        onDoubleClick={() => {
          navigator.clipboard.writeText(versionString)
        }}
        title={versionString}
        direction="column"
        alignItems="center"
        w="full"
        borderTop="1px"
        borderTopColor="whiteAlpha.300"
      >
        <Flex justifyContent="center" my="4">
          <HStack spacing={2} shouldWrapChildren alignItems="center">
            <NotificationCenter />
            <VStack gap="0">
              <Tooltip label={t`Feature updates`} placement="left" hasArrow>
                <div
                  onPointerEnter={() =>
                    analytics.event('TopBar-FeatureUpdates-Hover')
                  }
                >
                  <IconButton
                    aria-label="Feature Updates"
                    fontSize="16px"
                    icon={<AiFillGift />}
                    size="sm"
                    isRound
                    bg="none"
                    data-stonly="feature-updates"
                    color={'gray.800'}
                    _hover={{
                      bg: 'gray.400',
                    }}
                    _active={{
                      bg: 'gray.500',
                    }}
                  />
                </div>
              </Tooltip>
              <ProfileMenu />
            </VStack>
          </HStack>
        </Flex>
        {hasSuperPowers && (
          <Box position="absolute" left="0" bottom="0">
            <Link to={`/superpowers/organization/${organizationId}`}>
              <Icon
                ml="2px"
                as={RiShieldFlashFill}
                transformOrigin="50% 50%"
                _hover={{
                  transform: 'scale(1.7) translate(2px, -2px)',
                  animationDelay: '700ms',
                }}
                transition="all 700ms"
              />
            </Link>
          </Box>
        )}
      </Flex>
    </Flex>
  )
}

export default SidebarContent
