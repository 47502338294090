import analytics, {
  InitConfig,
  PostHogInstance,
  UTMParams,
  init as initAnalytics,
} from '@capturi/analytics'
import { Team, useCurrentUser, useTeams } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import merge from 'lodash/merge'
import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'

import { INTERCOM_APP_ID, POSTHOG_API_KEY, STONLY_API_KEY } from './keys'

const utmParameters: (keyof UTMParams)[] = [
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_term',
]

function getUtmParams(searchParams: URLSearchParams): UTMParams {
  return utmParameters.reduce((memo, param) => {
    memo[param] = searchParams.get(param)
    return memo
  }, {} as UTMParams)
}

function omitUTMParams(searchParams: URLSearchParams): URLSearchParams {
  const result = new URLSearchParams()
  for (const [key, value] of searchParams.entries()) {
    if (utmParameters.includes(key as keyof UTMParams)) {
      continue
    }
    result.set(key, value)
  }
  return result
}

export const withTracker = <P extends { noChrome?: boolean }>(
  WrappedComponent: React.FC<P>,
  config?: InitConfig,
): React.FC<P> => {
  const searchParams = new URLSearchParams(window.location.search)
  const utmParams = getUtmParams(searchParams)

  return (props) => {
    const location = useLocation()
    const user = useCurrentUser()
    const { enableIntercom } = useFeatureFlags()
    const { getTeamByUid } = useTeams()
    const team = getTeamByUid(user.teamUid || '')

    const teamLeadsTeams = useMemo(() => {
      return user.teamLeadTeamUids
        ? user.teamLeadTeamUids
            .map(getTeamByUid)
            .filter((team): team is Team => team !== undefined)
        : undefined
    }, [getTeamByUid, user.teamLeadTeamUids])

    // biome-ignore lint/correctness/useExhaustiveDependencies: the feature flags is loaded after this is loaded
    useEffect(() => {
      const mergedConfig = merge(
        {
          posthog: {
            token: POSTHOG_API_KEY,
            config: {
              disable_session_recording: true,
              api_host: 'https://posthog.capturi.ai',
              request_batching: true,
              loaded: (posthog: PostHogInstance) => {
                if (user.ownerUid) posthog.identify(user.ownerUid)
                if (user.organizationId)
                  posthog.group('organizationId', user.organizationId, {
                    organizationId: user.organizationId,
                  })
                if (user.domain)
                  posthog.group('domain', user.domain, {
                    domain: user.domain,
                  })
              },
            },
          },
          intercom: enableIntercom
            ? {
                token: INTERCOM_APP_ID,
              }
            : undefined,
          utmParams,
          debug: process.env.NODE_ENV === 'development',
          stonly: {
            token: STONLY_API_KEY,
          },
        },
        config,
      )
      initAnalytics(mergedConfig)

      // Remove UTM parameters as the last statement
      if (Object.values(utmParams).filter(Boolean).length > 0) {
        const newParams = omitUTMParams(searchParams).toString()
        const search = newParams.length === 0 ? '' : `?${newParams}`
        // Don't use react-router to update search params as this would trigger a navigation and therefore an extra page view
        window.history.replaceState(null, '', location.pathname + search)
      }
    }, [enableIntercom])

    useEffect(() => {
      if (user.id) {
        analytics.identify(user.ownerUid, user, team, teamLeadsTeams)
      }
    }, [user, team, teamLeadsTeams])

    useEffect(() => {
      analytics.pageview(location.pathname + location.search)
    }, [location])

    return <WrappedComponent {...props} />
  }
}
