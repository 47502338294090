import { SavedPhoneFilter } from '@capturi/api-filters'
import {
  OnChangeValue,
  OptionProps,
  PopoutSelect,
  ReactSelectProps,
  SelectOption,
  components,
} from '@capturi/ui-select'
import { Box, Flex, Stack } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import React, { useMemo } from 'react'
import { MdNotificationsActive } from 'react-icons/md'

import * as msgs from '../../../../messageDescriptors'

type FilterOption = SelectOption & {
  filter?: SavedPhoneFilter
}

const Option: React.ComponentType<OptionProps<FilterOption, boolean>> = (
  props,
) => {
  const { label, filter } = props.data
  return (
    <components.Option {...props}>
      <Flex align="center">
        <Box noOfLines={1} wordBreak="break-all">
          {label}
        </Box>
        <Stack ml="auto" flex="0 0 auto" direction="row" pl={1} spacing={1}>
          {filter?.isSubscribed === true && (
            <Box
              as={MdNotificationsActive}
              boxSize="18px"
              color="textMuted"
              aria-label={i18n._(msgs.notifications.enabled())}
              title={i18n._(msgs.notifications.enabled())}
            />
          )}
        </Stack>
      </Flex>
    </components.Option>
  )
}

type SavedPhoneFilterSelectProps = {
  value?: SavedPhoneFilter
  onChange: (filter: SavedPhoneFilter) => void
  options: SavedPhoneFilter[]
} & Omit<
  ReactSelectProps<FilterOption, boolean>,
  'value' | 'onChange' | 'options'
>

function SavedPhoneFilterSelect({
  value: valueProp,
  options: optionProp,
  onChange,
  ...reactSelectProps
}: SavedPhoneFilterSelectProps): React.ReactElement {
  const { options, value } = useMemo(() => {
    const options = optionProp.map((x) => ({
      value: x.uid,
      label: x.name,
      filter: x,
    }))

    let value = null
    if (valueProp) {
      value = {
        value: valueProp.uid,
        label: valueProp.name,
        filter: valueProp,
      }
    }
    return {
      options,
      value,
    }
  }, [optionProp, valueProp])

  const handleChange = (option: OnChangeValue<FilterOption, boolean>): void => {
    if (option == null) {
      return
    }
    const filter = (option as FilterOption).filter
    if (!filter) {
      return
    }
    onChange(filter)
  }

  return (
    <PopoutSelect
      placeholder={t`Search for a segment ...`}
      options={options}
      value={value}
      onChange={handleChange}
      components={{ Option }}
      autoFocus={true}
      noOptionsMessage={() => t`No options`}
      {...reactSelectProps}
    />
  )
}

export default SavedPhoneFilterSelect
