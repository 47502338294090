import { generateImageUrl } from '@capturi/request'
import {
  Avatar,
  AvatarBadge,
  Box,
  BoxProps,
  Stack,
  StackProps,
  Text,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import React from 'react'
import { FiPlayCircle } from 'react-icons/fi'
import { MdFlag, MdMessage } from 'react-icons/md'

import RelativeTimeFormat from '../../../../components/RelativeTime'
import {
  AudioSnippetNotification,
  CommentNotification,
  Notification,
} from '../../types'
import UnreadMarker from '../UnreadMarker'

const DateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

export const EmphasizedText: React.FC<BoxProps> = (props) => (
  <Text fontSize="md" fontWeight={500} lineHeight={1} {...props} />
)
export const CaptionText: React.FC<BoxProps> = (props) => (
  <Text fontSize="xs" fontWeight={400} color="textMuted" {...props} />
)

export const TimeSinceText: React.FC<BoxProps> = (props) => (
  <Box display="inline-block" fontSize="xs" whiteSpace="nowrap" {...props} />
)

export const NotificationItem: React.FC<{ read: boolean } & StackProps> = ({
  read,
  children,
  ...restProps
}) => {
  return (
    <Stack
      direction="row"
      spacing={4}
      alignItems="center"
      position="relative"
      {...restProps}
      w="100%"
    >
      {children}
      <UnreadMarker flex="0 0 auto" opacity={read ? 0 : 1} />
    </Stack>
  )
}

export const NotificationItemLeft: React.FC<BoxProps> = (props) => (
  <Box flex="0 0 auto" alignSelf="flex-start" mt={1} {...props} />
)
export const NotificationItemContent: React.FC<BoxProps> = (props) => (
  <Box flex="1" {...props} />
)

export const DefaultItem: React.FC<Notification> = (notification) => {
  return (
    <NotificationItem read={notification.read}>
      <NotificationItemLeft>
        <Box
          boxSize="2rem"
          border={0}
          bg="primary.500"
          color="white"
          borderRadius="full"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box as={MdFlag} boxSize="1rem" />
        </Box>
      </NotificationItemLeft>
      <NotificationItemContent>
        <Box>
          <Text as="span" pr={2}>
            <Trans>You have received a notification</Trans>
          </Text>
          <TimeSinceText
            color={notification.read ? 'textMuted' : 'primary.500'}
          >
            <RelativeTimeFormat date={notification.dateTime} />
          </TimeSinceText>
        </Box>
      </NotificationItemContent>
    </NotificationItem>
  )
}

export const CommentItem: React.FC<CommentNotification> = (notification) => {
  const profileImageUrl = generateImageUrl(
    notification.commentCreatedBy.profileImage?.key,
    {
      size: 32,
    },
  )
  return (
    <NotificationItem read={notification.read}>
      <NotificationItemLeft>
        <Avatar
          name={notification.commentCreatedBy.name}
          src={profileImageUrl}
          size="sm"
        >
          <AvatarBadge
            boxSize="1.25em"
            border={0}
            bg="primary.500"
            color="white"
          >
            <Box as={MdMessage} boxSize="10px" />
          </AvatarBadge>
        </Avatar>
      </NotificationItemLeft>
      <NotificationItemContent minW={0}>
        <Box>
          <EmphasizedText as="span" pr={2}>
            <Trans>
              {notification.commentCreatedBy.name} has sent you a comment
            </Trans>
          </EmphasizedText>
          <TimeSinceText
            color={notification.read ? 'textMuted' : 'primary.500'}
          >
            <RelativeTimeFormat date={notification.dateTime} />
          </TimeSinceText>
        </Box>
        <Text noOfLines={1} wordBreak="break-all">
          {notification.commentPreview}
        </Text>
        <CaptionText mt={1}>
          <Trans>Concerning conversation on</Trans>{' '}
          {i18n.date(notification.dateTime, DateFormatOptions)}
        </CaptionText>
      </NotificationItemContent>
    </NotificationItem>
  )
}

export const AudioSnippetItem: React.FC<AudioSnippetNotification> = (
  notification,
) => {
  const profileImageUrl = generateImageUrl(
    notification.audioSnippetCreatedBy.profileImage?.key,
    {
      size: 32,
    },
  )
  return (
    <NotificationItem read={notification.read}>
      <NotificationItemLeft>
        <Avatar
          name={notification.audioSnippetCreatedBy.name}
          src={profileImageUrl}
          size="sm"
        >
          <AvatarBadge
            boxSize="1.25em"
            border={0}
            bg="primary.500"
            color="white"
          >
            <Box as={FiPlayCircle} boxSize="10px" />
          </AvatarBadge>
        </Avatar>
      </NotificationItemLeft>
      <NotificationItemContent minW={0}>
        <Box>
          <EmphasizedText as="span" pr={2}>
            {notification.audioSnippetTitle}
          </EmphasizedText>
          <TimeSinceText
            color={notification.read ? 'textMuted' : 'primary.500'}
          >
            <RelativeTimeFormat date={notification.dateTime} />
          </TimeSinceText>
        </Box>
        <Text noOfLines={1} wordBreak="break-all">
          <Trans>New audio snippet added to the library</Trans>
        </Text>
      </NotificationItemContent>
    </NotificationItem>
  )
}
