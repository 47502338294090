import { Button } from '@capturi/ui-components'
import { OnChangeValue, Select, SelectOption } from '@capturi/ui-select'
import {
  Box,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

import { CustomNumberPropFilterProps, Range } from './types'

enum Operator {
  LTE = '<=',
  GTE = '>=',
  RANGE = '-',
}

export function CustomNumberPropRange({
  value: valueProp,
  setValue: setValueProp,
  resetValue,
  onClose,
}: CustomNumberPropFilterProps<'Range'>): React.ReactElement {
  const [value, setValue] = React.useState(() => {
    return valueProp ?? { min: 0, max: undefined }
  })

  const operatorOptions = [
    {
      value: Operator.GTE,
      label: t`Minimum`,
    },
    {
      value: Operator.LTE,
      label: t`Maximum`,
    },
    {
      value: Operator.RANGE,
      label: t`Between`,
    },
  ]

  const operator = React.useMemo(() => {
    const { min, max } = value
    if (min == null && max == null) {
      return Operator.GTE
    }
    if (min != null && max == null) {
      return Operator.GTE
    }
    if (min == null && max != null) {
      return Operator.LTE
    }
    return Operator.RANGE
  }, [value])

  const setOperator = (newOperator: Operator): void => {
    if (newOperator === Operator.GTE) {
      setValue?.({
        min: value.min ?? value.max ?? 1,
        max: undefined,
      })
    }
    if (newOperator === Operator.LTE) {
      setValue?.({
        min: undefined,
        max: value.max ?? value.min ?? 1,
      })
    }
    if (newOperator === Operator.RANGE) {
      setValue?.({
        min: value.min ?? value.max ?? 1,
        max: value.max ?? value.min ?? 1,
      })
    }
  }

  const applyValue = (value: Range | undefined): void => {
    if (value?.min === undefined && value?.max === undefined) {
      resetValue?.()
    } else {
      setValueProp?.(value)
    }
    onClose?.()
  }

  const showMinInput = [Operator.GTE, Operator.RANGE].includes(operator)
  const showMaxInput = [Operator.LTE, Operator.RANGE].includes(operator)

  return (
    <Flex p={2}>
      <Stack spacing="2" direction="row">
        <Box minW="8em">
          <Select
            value={operatorOptions.find((x) => x.value === operator)}
            options={operatorOptions}
            onChange={(option: OnChangeValue<SelectOption, false>) => {
              if (option == null) return
              setOperator(option.value as Operator)
            }}
          />
        </Box>
        {showMinInput && (
          <Box w="5.2em">
            <ValueInput
              value={value.min ?? undefined}
              min={0}
              max={
                operator === Operator.RANGE && value.max != null
                  ? value.max
                  : undefined
              }
              onChange={(newValue) => setValue?.({ ...value, min: newValue })}
            />
          </Box>
        )}
        {showMaxInput && (
          <Box w="5.2em">
            <ValueInput
              value={value.max ?? undefined}
              min={
                operator === Operator.RANGE && value.min != null
                  ? value.min
                  : undefined
              }
              onChange={(newValue) => setValue?.({ ...value, max: newValue })}
            />
          </Box>
        )}
      </Stack>
      <Button
        primary
        ml="auto"
        variant="ghost"
        onClick={() => applyValue(value)}
      >
        <Trans>OK</Trans>
      </Button>
    </Flex>
  )
}

const ValueInput: React.FC<{
  min?: number
  max?: number
  value?: number
  onChange?: (value: number | undefined) => void
}> = ({ min, max, value, onChange }) => {
  return (
    <NumberInput
      min={min}
      max={max}
      inputMode="numeric"
      onChange={(valueAsString, valueAsNumber) => {
        if (valueAsString === '') {
          onChange?.(undefined)
          return
        }
        if (!Number.isInteger(valueAsNumber)) return
        onChange?.(valueAsNumber)
      }}
      value={value}
      size="sm"
    >
      <NumberInputField
        _focus={{
          boxShadow: '0 0 0 3px #004EFC33',
        }}
      />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  )
}
