export type Flags = {
  // Default select employee speaker tab
  employeeAsDefaultSpeakerTab: boolean
  // Enable Sentiment feature
  enableSentimentFeature: boolean
  // Enable Intercom guided tours
  showStatus: boolean
  // Enable External ID segmentation filter
  showExternalId: boolean
  // Hide customer insights data on the tracker-analytics page and coaching pages from plain users.
  hideCustomerInsightsFromPlainUsers: boolean
  // This is a "Velkommen" specific feature. When opening the comment dialog from the tracker examples drawer add tracker info to the comment input by default.
  _Velkommen_trackerExampleCommentPrefixFeature: boolean
  // enable intercom widget + tracking
  enableIntercom: boolean
  // Enable probability in tracker configuration
  enableProbability: boolean
  // Determines whether the user has access to master trackers
  enableMasterTracker: boolean
  // Enables text/cases/emails pages and filters
  enableText: boolean
  // Removes stuff not available for chat
  isChatOrg: boolean
  // Removes stuff not available for archive orgs (most stuff)
  isArchiveOrg: boolean
  // On pages where the user can select a channel, prefer text channel as default
  useEmailChannelAsDefault: boolean
  // Determines whether an org should be treated as a public or private org
  isPublicOrg: boolean
  // Controls whether or not "Download transcription" buttons will be visible in Conversation page
  showTranscriptionDownloadButtons: boolean
  //Determines whether the `Speakers` filter should be visible
  useSpeakersFilter: boolean
  //Determines whether the org is of a more sales oriented nature (several features does not make sense to display to such orgs, such as the new Homepage)
  hideHomepage: boolean
  //Whether the user should be able to see the report subscription button on the homepage
  showReportSubscriptionButton: boolean
}

export const DEFAULT_FLAGS: Flags = {
  employeeAsDefaultSpeakerTab: false,
  enableSentimentFeature: true,
  showStatus: false,
  showExternalId: false,
  hideCustomerInsightsFromPlainUsers: false,
  _Velkommen_trackerExampleCommentPrefixFeature: false,
  enableIntercom: false,
  enableProbability: true,
  enableMasterTracker: false,
  enableText: false,
  useEmailChannelAsDefault: false,
  useSpeakersFilter: false,
  isPublicOrg: false,
  isChatOrg: false,
  isArchiveOrg: false,
  showTranscriptionDownloadButtons: false,
  hideHomepage: false,
  showReportSubscriptionButton: false,
}
