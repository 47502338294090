import { ErrorBoundary } from '@capturi/react-utils'
import React from 'react'
import { Route, Routes } from 'react-router'

import routes from './routes'

export { routes }

const ConversationsPage = React.lazy(() => import('./ConversationsPage'))

const Conversations: React.FC = () => {
  return (
    <ErrorBoundary>
      <Routes>
        <Route path={'/:uid/*'} element={<ConversationsPage />} />
        <Route path={'/'} element={<ConversationsPage />} />
      </Routes>
    </ErrorBoundary>
  )
}

export default Conversations
