import { List, ListItem } from '@capturi/ui-components'
import { Box, BoxProps, Button, Flex, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import conversationRoutes from 'pages/Conversations/routes'
import libraryRoutes from 'pages/Library/routes'
import React from 'react'
import { useNavigate } from 'react-router'
import useSWRInfinite from 'swr/infinite'

import { api as notificationApi } from '../../api'
import { useNotificationsContext } from '../../contexts/useNotifications'
import { useNotificationsData } from '../../contexts/useNotificationsData'
import { NotificationEvent, log } from '../../events'
import {
  AudioSnippetNotification,
  CommentNotification,
  Notification,
  NotificationsListResponse,
} from '../../types'
import { AudioSnippetItem, CommentItem, DefaultItem } from '../NotificationItem'

const Message: React.FC<BoxProps> = (props) => (
  <Text color="textMuted" textAlign="center" my={4} {...props} />
)

type Props = {
  onClose: () => void
}

const Notifications: React.FC<Props> = ({ onClose }) => {
  const { markSpecificAsRead } = useNotificationsContext()
  const navigate = useNavigate()

  const api = notificationApi()

  const PAGE_SIZE = 5
  const { data, error, size, setSize } =
    useSWRInfinite<NotificationsListResponse>(
      (index, previousPageData) => {
        // reached the end
        if (
          previousPageData &&
          previousPageData.notifications.length < PAGE_SIZE
        )
          return null

        const [url] = api.getNotifications(PAGE_SIZE, index, true)
        return url
      },
      {
        suspense: false,
      },
    )

  const isLoadingInitialData = !(data || error)
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === 'undefined')
  const isEmpty = data?.[0].notifications?.length === 0
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.notifications.length < PAGE_SIZE)

  const renderItem = (n: Notification): JSX.Element => {
    switch (n.type) {
      case 'comment': {
        return <CommentItem {...(n as CommentNotification)} />
      }
      case 'audio-snippet': {
        return <AudioSnippetItem {...(n as AudioSnippetNotification)} />
      }
      default: {
        return <DefaultItem {...n} />
      }
    }
  }

  const onNotificationClicked = async (n: Notification): Promise<void> => {
    switch (n.type) {
      case 'comment': {
        const cn = n as CommentNotification
        navigate(
          conversationRoutes.conversation(cn.conversationUid, 'coaching'),
        )
        break
      }
      case 'audio-snippet': {
        const an = n as AudioSnippetNotification
        navigate({
          pathname: libraryRoutes.audioSnippet(),
          search: new URLSearchParams({
            snippet: an.audioSnippetUid,
          }).toString(),
        })
        break
      }
      default: {
        if (n.conversationUid != null) {
          navigate(conversationRoutes.conversation(n.conversationUid))
        }
      }
    }
    if (!n.read) {
      await markSpecificAsRead([n.uid])
    }
    onClose()
  }

  const notifications = useNotificationsData(
    data?.flatMap((p) => p.notifications || []),
  )

  const handleLoadMoreClicked = (): void => {
    setSize(size + 1)
    log(NotificationEvent.LoadMore)
  }

  return (
    <Box maxH="80vh" overflow="auto">
      {isEmpty && (
        <Message>
          <Trans>You do not have any notifications</Trans>
        </Message>
      )}
      <List>
        {notifications.map((n) => (
          <ListItem key={n.uid} button onClick={() => onNotificationClicked(n)}>
            {renderItem(n)}
          </ListItem>
        ))}
      </List>
      {!isEmpty && (
        <Flex justifyContent="center" py={2} px={4}>
          <Button
            onClick={handleLoadMoreClicked}
            isDisabled={isReachingEnd}
            isLoading={isLoadingMore}
            size="sm"
          >
            {isReachingEnd ? (
              <Trans>No more notifications</Trans>
            ) : (
              <Trans>Load more</Trans>
            )}
          </Button>
        </Flex>
      )}
    </Box>
  )
}

export default Notifications
