import { PhraseNearnessSettings } from '@capturi/api-trackers'
import { Highlight } from '@capturi/ui-components'
import { IconButton, Stack, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { MdAdd, MdEdit } from 'react-icons/md'
import { CellProps } from 'react-table'

import { PhraseState } from '../../../../types'
import { PhraseNearnessFormModel } from '../PhraseNearnessSettings/PhraseNearnessForm'
import { PhraseNearnessPopover } from '../PhraseNearnessSettings/PhraseNearnessPopover'

export function PhraseNearCell(
  props: CellProps<PhraseState, PhraseNearnessFormModel>,
): React.ReactElement {
  const { value: settings, row, updatePhraseField } = props
  return (
    <PhraseNearnessCell
      heading={
        <Trans>
          <Highlight>Must</Highlight> be mentioned near at least one of these
          phrases:
        </Trans>
      }
      phraseValue={row.original.value}
      value={settings}
      onChange={(newValue) => {
        updatePhraseField(row.index, { settings: { near: newValue } })
      }}
    />
  )
}

export function PhraseNotNearCell(
  props: CellProps<PhraseState, PhraseNearnessFormModel>,
): React.ReactElement {
  const { value: settings, row, updatePhraseField } = props
  return (
    <PhraseNearnessCell
      heading={
        <Trans>
          Must <Highlight>not</Highlight> be mentioned near at least one of
          these phrases:
        </Trans>
      }
      phraseValue={row.original.value}
      value={settings}
      onChange={(newValue) => {
        updatePhraseField(row.index, { settings: { notNear: newValue } })
      }}
    />
  )
}

const PhraseNearnessCell: React.FC<{
  heading: React.ReactNode
  value: PhraseNearnessSettings | undefined
  phraseValue: string
  onChange: (newValue: PhraseNearnessSettings | undefined) => void
}> = ({ heading, value, onChange }) => {
  const hasPhrases = value && value.phrases.length > 0
  return (
    <Stack direction="row" spacing={1} align="center">
      {hasPhrases && (
        <Text>
          <span>{value?.phrases.join(', ')}</span>
        </Text>
      )}
      <PhraseNearnessPopover
        heading={heading}
        defaultValues={value}
        closeOnBlur={false}
        onSubmit={(data) => {
          const newValue = data.phrases.length ? data : undefined
          onChange(newValue)
        }}
      >
        <IconButton
          size="xs"
          aria-label={t`Edit phrase nearness settings`}
          icon={hasPhrases ? <MdEdit /> : <MdAdd />}
        />
      </PhraseNearnessPopover>
    </Stack>
  )
}
