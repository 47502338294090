import { Box, Skeleton, Stack } from '@chakra-ui/react'
import React from 'react'

/**
 * Skeleton for the filter bar.
 * Values for heights and margins etc. are precise to avoid layout shifting once content is loaded.
 */
const SkeletonLoader: React.FC<{ comparable?: boolean }> = ({ comparable }) => {
  return (
    <Box pos="relative" overflowY="hidden" maxW="100%">
      {/* skeleton for a simple filter bar */}
      <Box mb={2}>
        <Stack direction="row" spacing={1} my="2px">
          <Skeleton h={8} w="2rem" borderRadius="md" />
          <Skeleton h={8} w="8rem" borderRadius="md" />
          <Skeleton h={8} w="6rem" borderRadius="md" />
          <Skeleton h={8} w="2rem" borderRadius="md" />
          <Skeleton
            h={8}
            w="2rem"
            borderRadius="full"
            transform="translateX(16px)"
          />
        </Stack>
      </Box>
      {/* skeleton for "add comparison" link */}
      {comparable && (
        <Box display="inline-block">
          {/* prevent collapsing margins */}
          <Skeleton h="13px" w="5rem" my={2} borderRadius="md" />
        </Box>
      )}
    </Box>
  )
}

export default SkeletonLoader
