import qs from 'query-string'
import React from 'react'
import { useSearchParams } from 'react-router'

type SyncOptions<TValue> = {
  skipInitialValue: boolean
  isEqualFn: (a: TValue | null, b: string | null) => boolean
}

const defaultOptions: SyncOptions<unknown> = {
  skipInitialValue: true,
  isEqualFn: (a, b) => {
    return String(a) === String(b)
  },
}

export default function useSyncSearchParam<TValue>(
  name: string,
  newValue: TValue,
  optionsArg?: Partial<SyncOptions<TValue>>,
): void {
  const hasInitialValueChanged = useHasInitialValueChanged(newValue)
  const [searchParams, setSearchParams] = useSearchParams()

  const optionsRef = React.useRef({ ...defaultOptions, ...(optionsArg ?? {}) })

  React.useEffect(() => {
    if (optionsRef.current.skipInitialValue && !hasInitialValueChanged) {
      return
    }

    const currentValue = searchParams.get(name)
    const newValueOrNull = newValue ?? null

    if (optionsRef.current.isEqualFn(newValueOrNull, currentValue)) {
      return
    }

    const parsedQueryString = qs.parse(searchParams.toString())
    const newParams = qs.stringify({
      ...parsedQueryString,
      [name]: newValueOrNull,
    })

    setSearchParams(newParams, { replace: true })
  }, [name, newValue, hasInitialValueChanged, searchParams, setSearchParams])
}

function useHasInitialValueChanged(value: unknown): boolean {
  const initialValueRef = React.useRef(value)
  const hasChangedRef = React.useRef(false)

  if (initialValueRef.current !== value) {
    hasChangedRef.current = true
  }

  return hasChangedRef.current
}
