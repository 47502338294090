import { FilterPeriodProviderProps } from '@capturi/filters'
import * as React from 'react'
import { createSearchParams, useSearchParams } from 'react-router'

export function withPeriodSyncToSearchParams<
  P extends FilterPeriodProviderProps,
>(
  WrappedComponent: React.ComponentType<P>,
  searchParamKey?: string,
): React.FC<P> {
  const EnhancedComponent: React.FC<P> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [period] = React.useState(() => {
      if (searchParamKey) {
        return searchParams.get(searchParamKey) ?? undefined
      }
      const periodParam = searchParams.get('period')
      // `referenceDate` is still used in email templates. Use it if present. Should be deprecated
      const referenceDateParam = searchParams.get('referenceDate')
      return periodParam ?? referenceDateParam ?? undefined
    })
    return (
      <WrappedComponent
        initialPeriod={period}
        onPeriodChange={(definition) => {
          const newParams = createSearchParams(searchParams)
          newParams.set(searchParamKey ?? 'period', definition.name)
          setSearchParams(newParams, { replace: true })
        }}
        {...props}
      />
    )
  }
  return EnhancedComponent
}
