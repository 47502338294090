import { useOrganizationSettings } from '@capturi/api-organization'
import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { SegmentStatesProvider } from '@capturi/filters'
import { ErrorBoundary, usePageTitle } from '@capturi/react-utils'
import { setAsDemoOrg } from '@capturi/request'
import { useOrganization, useUsers } from '@capturi/stores'
import { PageLoader } from '@capturi/ui-components'
import { Box, Flex } from '@chakra-ui/react'
import {
  withInitialValuesFromRole,
  withSavedFilterUrlSync,
} from 'features/filters/filter-state-enhancers'
import Links from 'linksRouting/Links'
import Analysis from 'pages/Analysis/Router'
import {
  basePath as analysisBasePath,
  getRoutes as getAnalysisRoutes,
} from 'pages/Analysis/routes'
import AskAIPage from 'pages/AskAI/AskAIPage'
import askAIRoutes from 'pages/AskAI/routes'
import CasesPage, { routes as caseRoutes } from 'pages/Cases'
import { CoachingPage, routes as coachingRoutes } from 'pages/Coaching'
import CommentsPage, { routes as commentsRoutes } from 'pages/CommentsPage'
import ConversationsPage, {
  routes as conversationRoutes,
} from 'pages/Conversations'
import DashboardsPage, { routes as dashboardRoutes } from 'pages/Dashboards'
import HomePage from 'pages/HomePage'
import KeyTopicsPage, { routes as keyTopicsRoutes } from 'pages/KeyTopics'
import LibraryPage, { routes as libraryRoutes } from 'pages/Library'
import { MetabaseReports } from 'pages/MetabaseReports'
import metabaseReportsRoutes from 'pages/MetabaseReports/routes'
import MyPage, { routes as myPageRoutes } from 'pages/MyPage'
import NotFoundPage from 'pages/NotFoundPage'
import Organization, { routes as organizationRoutes } from 'pages/Organization'
import ProfilePage, { routes as profileRoutes } from 'pages/ProfilePage'
import SavedFiltersPage, {
  routes as savedFiltersRoutes,
} from 'pages/SavedFiltersPage'
import ScoringPage, { routes as scoringRoutes } from 'pages/ScoringPage'
import SubscriptionsPage from 'pages/SubscriptionsPage/SubscriptionsPage'
import SummaryPage from 'pages/Summaries/SummaryPage'
import summariesRoutes from 'pages/Summaries/routes'
import TrackersPage, { routes as trackersRoutes } from 'pages/TrackersPage'
import TrendsV2, { trendsRootPath } from 'pages/TrendsV2'
import {
  Router as AnalyticsPageRouter,
  basePath as analyticsBasePath,
} from 'pages/analytics'
import React, { useEffect } from 'react'
import {
  Navigate,
  NavigateProps,
  RouteObject,
  useLocation,
  useRoutes,
} from 'react-router'
import CollapsibleSidebar from './Sidebar/CollapsibleSidebar'
import RedirectToConversation from './Sidebar/components/RedirectToConversation'
import WavesBackground from './WavesBackground'
import Workspace from './Workspace'

const SuperPowers = React.lazy(() => import('@capturi/superpowers/src'))

const RedirectToWithQuery = (props: NavigateProps): React.ReactElement => {
  const location = useLocation()
  return <Navigate {...props} to={props.to + location.search} />
}

const RedirectToRoot = (): React.ReactElement => {
  const { aiFeatures, useEmailChannelAsDefault, hideHomepage } =
    useFeatureFlags()
  const currentUser = useCurrentUser()
  return (
    <Navigate
      to={
        aiFeatures &&
        currentUser.isAdminOrTeamLead &&
        !useEmailChannelAsDefault &&
        !hideHomepage &&
        !currentUser.hasSuperPowers
          ? '/home'
          : conversationRoutes.root
      }
    />
  )
}

const routeConfig: RouteObject[] = [
  //Redirects
  {
    path: '/',
    element: <RedirectToRoot />,
  },
  {
    path: '/link/*',
    element: <Links />,
  },
  {
    path: '/home',
    element: <HomePage />,
  },
  {
    path: '/filters',
    element: <Navigate to={savedFiltersRoutes.root} replace />,
  },
  {
    path: '/library/*',
    element: <Navigate to={libraryRoutes.root} replace />,
  },
  {
    path: '/analytics/*',
    element: <Navigate to={analyticsBasePath} replace />,
  },
  {
    path: '/analytics/hitrate',
    element: <RedirectToWithQuery to={getAnalysisRoutes().hitrate} replace />,
  },
  {
    path: '/profile',
    element: <Navigate to={profileRoutes.root} replace />,
  },
  {
    path: '/organization/*',
    element: <Navigate to={organizationRoutes.root} replace />,
  },
  {
    path: '/trackers/*',
    element: <Navigate to={trackersRoutes.root} replace />,
  },
  {
    path: '/employees',
    element: <Navigate to={coachingRoutes.root} replace />,
  },
  {
    path: '/settings',
    element: <Navigate to={savedFiltersRoutes.root} replace />,
  },
  {
    path: '/analysis',
    element: <Navigate to={getAnalysisRoutes().hitrate} replace />,
  },
  {
    path: '/conversations/:uid/:conversationPageView',
    element: <RedirectToConversation />,
  },
  {
    path: '/conversations/:uid',
    element: <RedirectToConversation />,
  },
  {
    path: '/conversations',
    element: <RedirectToConversation />,
  },
  {
    path: '/settings/subscriptions',
    element: <SubscriptionsPage />,
  },
  { path: `${analyticsBasePath}/*`, element: <AnalyticsPageRouter /> },
  { path: `${trendsRootPath}/*`, element: <TrendsV2 /> },
  { path: `${myPageRoutes.root}/*`, element: <MyPage /> },
  { path: `${coachingRoutes.root}/*`, element: <CoachingPage /> },
  { path: `${commentsRoutes.root}/*`, element: <CommentsPage /> },
  { path: `${askAIRoutes.root}/*`, element: <AskAIPage /> },
  { path: `${keyTopicsRoutes.root}/*`, element: <KeyTopicsPage /> },
  { path: `${conversationRoutes.root}/*`, element: <ConversationsPage /> },
  { path: `${caseRoutes.root}/*`, element: <CasesPage /> },
  { path: `${analysisBasePath}/*`, element: <Analysis /> },
  {
    path: `${organizationRoutes.root}/*`,
    element: <Organization />,
  },
  { path: `${libraryRoutes.root}/*`, element: <LibraryPage /> },
  { path: `${profileRoutes.root}/*`, element: <ProfilePage /> },
  { path: `${trackersRoutes.root}/*`, element: <TrackersPage /> },
  { path: `${savedFiltersRoutes.root}/*`, element: <SavedFiltersPage /> },
  { path: `${dashboardRoutes.root}/*`, element: <DashboardsPage /> },
  { path: `${summariesRoutes.root}/*`, element: <SummaryPage /> },
  {
    path: `${metabaseReportsRoutes.root}/*`,
    element: <MetabaseReports />,
  },
  { path: `${scoringRoutes.root}/*`, element: <ScoringPage /> },
  { path: '/superpowers/*', element: <SuperPowers /> },
  { path: '*', element: <NotFoundPage /> },
]

type AppProps = {
  noChrome?: boolean
}
const SegmentStatesProviderWithPresets = withInitialValuesFromRole(
  withSavedFilterUrlSync(SegmentStatesProvider),
)

const App: React.FC<AppProps> = (props) => {
  usePageTitle('Capturi')
  const routesElement = useRoutes(routeConfig)

  //Pre fetching stuff
  useUsers()
  useOrganizationSettings()
  const { isInitialLoading: featureFlagsAreLoading } = useFeatureFlags()

  const { demoOrgSettings, isSucceeded: hasFetchedOrg } = useOrganization()

  useEffect(() => {
    if (demoOrgSettings?.newestConversationDate) {
      // makes funny stuff with how the dates are parsed.
      //So old conversations always looks as they are from today
      setAsDemoOrg(demoOrgSettings.newestConversationDate)
    }
  }, [demoOrgSettings])

  function renderContents(): React.ReactElement {
    //We have to wait until we have loaded the org before we can start loading the rest og the application
    if (!hasFetchedOrg || featureFlagsAreLoading) {
      return <PageLoader />
    }

    return (
      <SegmentStatesProviderWithPresets>
        <ErrorBoundary>
          <React.Suspense fallback={<PageLoader />}>
            {routesElement}
          </React.Suspense>
        </ErrorBoundary>
      </SegmentStatesProviderWithPresets>
    )
  }

  if (props.noChrome === true) {
    return (
      <Box width="100vw" height="100vh" bg="white">
        {renderContents()}
      </Box>
    )
  }

  return (
    <Flex>
      <WavesBackground />
      <CollapsibleSidebar />
      <Workspace>{renderContents()}</Workspace>
    </Flex>
  )
}

export default App
