import { memo } from 'react'
import { FC } from 'react'
import { FilterCriteriaComponentBaseProps } from '../../components/PhoneFilter/components/PhoneSegmentBuilder'
import { KeyTopicSelect } from './KeyTopicSelect'

export type PhoneKeyTopicSelectProps = FilterCriteriaComponentBaseProps<
  string[]
>

export const PhoneKeyTopicSelect: FC<PhoneKeyTopicSelectProps> = memo(
  (props) => {
    return <KeyTopicSelect channel="phone" {...props} />
  },
)
