import { ErrorBoundary } from '@capturi/react-utils'
import { Spinner } from '@capturi/ui-components'
import React from 'react'
import { Route, Routes } from 'react-router'

export { default as routes, trendsRootPath } from './routes'

const TrendTypePickerPage = React.lazy(
  () => import('./pages/TrendTypePickerPage'),
)
const LiveTrendsPage = React.lazy(() => import('./pages/LiveTrendsPage'))
const SegmentTrendsPage = React.lazy(() => import('./pages/SegmentTrendsPage'))

const TrendsV2PageRouter: React.FC = () => {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<Spinner display="block" m="5rem auto" />}>
        <Routes>
          <Route path="/" element={<TrendTypePickerPage />} />
          <Route path="/live/:liveTopicsUid?/*" element={<LiveTrendsPage />} />
          <Route
            path="/segment/:segmentUid?/*"
            element={<SegmentTrendsPage />}
          />
        </Routes>
      </React.Suspense>
    </ErrorBoundary>
  )
}

export default TrendsV2PageRouter
