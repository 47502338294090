import { Button } from '@capturi/ui-components'
import {
  Box,
  Circle,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import {
  MdClear,
  MdFilterList,
  MdList,
  MdPoll,
  MdSortByAlpha,
  MdSwapVert,
} from 'react-icons/md'

import { useStore } from '@tanstack/react-store'
import { trackersListSortByStore } from '../state'
import FilterTagsSelectPopover from './FilterTagsSelectPopover'

type FilterTagSelectType = {
  selectedTags: string[] | undefined
  setSelectedTags: (tags: string[]) => void
  children?: React.ReactNode
}

const FilterTagsPopover: React.FC<FilterTagSelectType> = ({
  selectedTags,
  setSelectedTags,
}: FilterTagSelectType) => {
  const hasSelectedTags = (selectedTags?.length ?? 0) > 0
  const sortBy = useStore(trackersListSortByStore)
  return (
    <Popover returnFocusOnClose={false}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <IconButton variant="ghost" aria-label={'Open filter'}>
              <Tooltip hasArrow label={t`Filter by tag`} placement="top">
                <Box position="relative">
                  <Icon
                    borderRadius={4}
                    as={MdFilterList}
                    aria-label="tag icon"
                  />
                  {hasSelectedTags && (
                    <Circle
                      border="1px solid white"
                      background="success"
                      position="absolute"
                      size="7px"
                      right="1px"
                      top="1px"
                    />
                  )}
                </Box>
              </Tooltip>
            </IconButton>
          </PopoverTrigger>
          <PopoverContent w="163px" overflow="hidden">
            <FilterTagsSelectPopover
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
            >
              <Flex
                alignItems="center"
                cursor="pointer"
                px={4}
                py="10px"
                mt="10px"
                gap="10px"
                transition="background 0.2s ease-in-out"
                _hover={{ background: 'gray.100' }}
                w="100%"
              >
                <Icon as={MdList} />
                <Text flexGrow={1}>
                  <Trans>Tags</Trans>
                </Text>
                {hasSelectedTags && (
                  <Circle
                    border="1px solid white"
                    background="success"
                    size="7px"
                  />
                )}
              </Flex>
            </FilterTagsSelectPopover>

            <Box pb={2} pt={3} px={4} backgroundColor="gray.50">
              <Flex
                justifyContent="space-between"
                fontSize="xs"
                textColor="gray.600"
              >
                <Button
                  h="25px"
                  isDisabled={!hasSelectedTags}
                  iconSpacing={1}
                  leftIcon={<Icon as={MdClear} boxSize={2} />}
                  onClick={() => {
                    setSelectedTags([])
                    onClose()
                  }}
                  px={0}
                  size="xs"
                  textTransform="uppercase"
                  variant="ghost"
                >
                  <Trans>Clear</Trans>
                </Button>

                <Menu>
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        as={Button}
                        h="25px"
                        iconSpacing={1}
                        leftIcon={<MdSwapVert />}
                        px={0}
                        size="xs"
                        textTransform="uppercase"
                        variant="ghost"
                      >
                        <Trans>Sort</Trans>
                      </MenuButton>
                      {isOpen && (
                        <Portal>
                          <MenuList>
                            <MenuItem
                              fontWeight={
                                sortBy === 'alphabetically'
                                  ? 'medium'
                                  : 'normal'
                              }
                              icon={<MdSortByAlpha />}
                              onClick={() => {
                                trackersListSortByStore.setState(
                                  () => 'alphabetically',
                                )
                              }}
                            >
                              <Trans>Alphabetically</Trans>
                            </MenuItem>
                            <MenuItem
                              fontWeight={
                                sortBy === 'hitrate' ? 'medium' : 'normal'
                              }
                              icon={<MdPoll />}
                              onClick={() => {
                                trackersListSortByStore.setState(
                                  () => 'hitrate',
                                )
                              }}
                            >
                              <Trans>Hit rate</Trans>
                            </MenuItem>
                          </MenuList>
                        </Portal>
                      )}
                    </>
                  )}
                </Menu>
              </Flex>
            </Box>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
export default FilterTagsPopover
