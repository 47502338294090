import { useCurrentUser } from '@capturi/core'
import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import {
  Subscription,
  SubscriptionCreatePayload,
  SubscriptionPatchPayload,
} from './types'

const cacheKey = 'subscriptions'

type SubscriptionLists = {
  own: Subscription[]
  others: Subscription[]
}

export const useCreateSubscription = (): UseMutationResult<
  Subscription,
  ResponseError,
  SubscriptionCreatePayload,
  ResponseError
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (model: SubscriptionCreatePayload) => {
      return request.post<Subscription>('subscriptions?api-version=3.3', {
        json: model,
      })
    },
    onSuccess: (newSubscription) => {
      queryClient.setQueryData<Subscription[]>([cacheKey], (oldData) => {
        if (!oldData) {
          return [newSubscription]
        }
        return [newSubscription, ...oldData]
      })
    },
  })
}

export const useUpdateSubscription = (): UseMutationResult<
  Subscription,
  ResponseError,
  Partial<SubscriptionPatchPayload>,
  ResponseError
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ uid, ...payload }) => {
      return request.patch(`subscriptions/${uid}?api-version=3.3`, {
        json: payload,
      })
    },
    onSuccess: (newSubscription) => {
      queryClient.setQueryData<Subscription[]>([cacheKey], (oldData) => {
        if (!oldData) {
          return [newSubscription]
        }
        return oldData.map((s) =>
          s.uid === newSubscription.uid ? newSubscription : s,
        )
      })
    },
  })
}

export const useSubscriptions = (): UseQueryResult<
  SubscriptionLists,
  ResponseError
> => {
  const currentUser = useCurrentUser()
  const subscriptionListSelector = useCallback(
    (data: Subscription[]): SubscriptionLists => {
      return data?.reduce<SubscriptionLists>(
        (memo, subscription) => {
          if (subscription.createdByUserUid === currentUser.id) {
            memo.own.push(subscription)
          } else {
            memo.others.push(subscription)
          }
          return memo
        },
        { own: [], others: [] },
      )
    },
    [currentUser],
  )

  return useQuery({
    queryKey: [cacheKey],
    queryFn: async () => {
      const result = await request.get<{ subscriptions: Subscription[] }>(
        'subscriptions?api-version=3.3',
      )
      return result.subscriptions
    },
    select: subscriptionListSelector,
  })
}

export const useDeleteSubscription = (): UseMutationResult<
  Subscription,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (subscriptionUid: string) => {
      return request.delete<Subscription>(
        `subscriptions/${subscriptionUid}?api-version=3.3`,
      )
    },
    onSuccess: (_, subscriptionUid) => {
      queryClient.setQueryData<Subscription[]>([cacheKey], (oldData) => {
        if (!oldData) {
          return []
        }
        return oldData.filter((s) => s.uid !== subscriptionUid)
      })
    },
  })
}
