import analytics from '@capturi/analytics'
import {
  Box,
  Flex,
  Heading,
  Skeleton,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

import { DefaultFallbackComponent } from '@capturi/react-utils'
import { Button } from '@capturi/ui-components'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

import { useEffectOnce } from 'react-use'
import SessionFailedProcessing from '../../../components/KeyTopics/FailedStates/SessionFailedProcessing'
import SessionNotFound from '../../../components/KeyTopics/FailedStates/SessionNotFound'
import { useKeyTopics } from '../../../hooks/useKeyTopics'
import { routes } from '../../KeyTopics/routes'
import { AnimateChangeInHeight } from '../Shared/AnimateHeight'
import {
  TopChangesWidgetCard,
  TopChangesWidgetCardSkeleton,
} from './TopChangesWidgetCard'
import { TopChangesWidgetReadySelector, selector } from './selector'
import { useWordCloud } from './useWordCloud'

type Props = {
  keyTopicsSessionUid: string | undefined
  startNewSession: () => void
}

const TopChangesWidgetSkeleton: React.FC = () => {
  return (
    <Box height="689px">
      <Skeleton height="21px" width="250px" mb="1" />
      <Skeleton height="14px" width="70%" mb="1" />
      <VStack alignItems="stretch" spacing={4}>
        <TopChangesWidgetCardSkeleton />
      </VStack>
    </Box>
  )
}

const getDiffElement = (amountVsExpectedDiff: number) => {
  return amountVsExpectedDiff >= 0 ? (
    <Trans>
      <chakra.span fontWeight="medium">
        {amountVsExpectedDiff} conversations more
      </chakra.span>{' '}
      than expected
    </Trans>
  ) : (
    <Trans>
      <chakra.span fontWeight="medium">
        {amountVsExpectedDiff} conversations less
      </chakra.span>{' '}
      than expected
    </Trans>
  )
}

const TopChangesReadyWidget: React.FC<{
  keyTopicsSessionUid: string | undefined
  data: TopChangesWidgetReadySelector
}> = ({ keyTopicsSessionUid, data }) => {
  const navigate = useNavigate()

  const firstItem = data.keyTopicClusters[0]
  const [selectedKeyTopicClusterId, setSelectedKeyTopicClusterId] = useState<
    string | undefined
  >(firstItem?.id)

  const selectedKeyTopicCluster = useMemo(
    () =>
      data.keyTopicClusters.find(
        (topic) => topic.id === selectedKeyTopicClusterId,
      ),
    [data, selectedKeyTopicClusterId],
  )

  if (
    data.keyTopicClusters.length > 0 &&
    selectedKeyTopicCluster === undefined
  ) {
    setSelectedKeyTopicClusterId(data.keyTopicClusters[0].id)
  }

  useEffectOnce(() => {
    if (firstItem) {
      analytics.event('top_changes_loaded', {
        signficance: firstItem.significant,
      })
    }
  })

  const { wordCloud, trendWordCloud, notablePhrases } = useWordCloud({
    keyTopicsSessionUid,
    selectedKeyTopicId: selectedKeyTopicClusterId,
  })

  return (
    <AnimateChangeInHeight overflowYVisble>
      <Flex
        flexDir="column"
        minH={data.keyTopicClusters.length > 0 ? '689px' : undefined}
      >
        <Heading
          fontSize="2xl"
          textShadow="textExtended"
          data-stonly="Top changes"
          width="fit-content"
        >
          <Trans>Top changes</Trans>
        </Heading>
        <Flex>
          {firstItem && (
            <Text flex="1" fontSize="sm" color="gray.600">
              <Trans>
                <chakra.span fontWeight="medium">{firstItem.label}</chakra.span>{' '}
                and related topics show the biggest change compared to previous
                period.
              </Trans>{' '}
              {getDiffElement(firstItem.amountVsExpectedDiff)}
              {notablePhrases.length > 0 ? (
                <>
                  ,{' '}
                  <Trans>
                    with notable phrases including{' '}
                    <chakra.span fontWeight="medium">
                      {notablePhrases[0]}
                    </chakra.span>
                  </Trans>
                  .
                </>
              ) : (
                <>.</>
              )}
            </Text>
          )}
          {keyTopicsSessionUid && (
            <Button
              onClick={() => {
                analytics.event('top_changes_explore_more')
                navigate(routes.root)
              }}
              primary
              size="xs"
              variant="link"
            >
              <Trans>Explore more</Trans>
            </Button>
          )}
        </Flex>
        <Box flex="1 1 auto">
          <TopChangesWidgetCard
            keyTopicsSessionUid={keyTopicsSessionUid}
            data={data}
            wordCloud={wordCloud}
            trendWordCloud={trendWordCloud}
            notablePhrases={notablePhrases}
            selectedKeyTopicCluster={selectedKeyTopicCluster}
            setSelectedKeyTopicClusterId={setSelectedKeyTopicClusterId}
          />
        </Box>
      </Flex>
    </AnimateChangeInHeight>
  )
}

export const TopChangesWidget: React.FC<Props> = ({
  keyTopicsSessionUid,
  startNewSession,
}) => {
  const { data, isLoading, error } = useKeyTopics({
    channel: 'phone',
    select: selector,
  })

  if (error) return <DefaultFallbackComponent error={error} />
  if (data?.status === 'failed')
    return <SessionFailedProcessing startNewSession={startNewSession} />
  if (data?.status === 'gone') {
    return <SessionNotFound startNewSession={startNewSession} />
  }
  if (isLoading || !data || data.status === 'loading') {
    return <TopChangesWidgetSkeleton />
  }

  return (
    <TopChangesReadyWidget
      keyTopicsSessionUid={keyTopicsSessionUid}
      data={data}
    />
  )
}
