import { TrackerFormModel } from '@capturi/api-trackers'
import { FormLabel } from '@capturi/ui-components'
import { FormControl, Textarea } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { Controller } from 'react-hook-form'
import { MdMessage } from 'react-icons/md'

export const DescriptionField: React.FC = () => {
  return (
    <FormControl>
      <FormLabel
        htmlFor="tracker_description"
        leftIcon={<MdMessage />}
        data-stonly="tracker_description"
      >
        <Trans>Description</Trans>
      </FormLabel>
      <Controller<TrackerFormModel, 'description'>
        name="description"
        render={({ field }) => {
          return (
            <Textarea
              {...field}
              value={field.value ?? ''}
              onChange={(val) => {
                field.onChange(val)
              }}
              placeholder={t`
              Add a description that explains what the tracker should be used for ...`}
              size="sm"
            />
          )
        }}
      />
    </FormControl>
  )
}
