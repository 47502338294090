import { ScoreState } from '@capturi/api-filters'
import { useScores } from '@capturi/api-scoring'
import { Button } from '@capturi/ui-components'
import {
  OnChangeValue,
  OptionProps,
  Select,
  SelectOption,
  components,
} from '@capturi/ui-select'
import {
  Box,
  Collapse,
  Divider,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

import {
  FilterCriteriaComponentBaseProps,
  FilterCriteriaSelect,
} from '../../components/PhoneFilter/components/PhoneSegmentBuilder'

type ScoreSelectProps = FilterCriteriaComponentBaseProps<ScoreState[]>

type ScoreSelectOption = SelectOption & {
  maxScore?: number
}

type ScoreRange = {
  min?: number
  max?: number
}

const DEFAULT_RANGE = {
  min: 5,
  max: undefined,
}

const Option: React.ComponentType<OptionProps<ScoreSelectOption, boolean>> = (
  props,
) => {
  const { label, maxScore } = props.data as ScoreSelectOption
  return (
    <components.Option {...props}>
      <Box noOfLines={1} wordBreak="break-all">
        {label}
      </Box>
      <Text fontSize="xs" color="textMuted" noOfLines={1} wordBreak="break-all">
        <Trans>max. score: {maxScore}</Trans>
      </Text>
    </components.Option>
  )
}

export function ScoreSelect({
  value: valueProp,
  setValue: setValueProp,
  onClose,
}: ScoreSelectProps): React.ReactElement {
  const { data: scores } = useScores()

  const firstValue = valueProp?.[0]

  const [value, setValue] = React.useState(firstValue?.uid)
  const [range, setRange] = React.useState<ScoreRange>(() => {
    if (firstValue) {
      return {
        min: firstValue.min ?? undefined,
        max: firstValue.max ?? undefined,
      }
    }
    return DEFAULT_RANGE
  })

  const hasValue = value !== undefined

  const selectProps = React.useMemo(() => {
    const options = (scores ?? []).map((x) => ({
      value: x.uid,
      label: x.name,
      maxScore: x.maxPossibleScore,
    }))
    return {
      options,
      value: options.find((x) => x.value === value),
    }
  }, [scores, value])

  const applyValue = (): void => {
    if (value) {
      setValueProp?.([
        {
          uid: value,
          ...range,
        },
      ])
    }
    onClose?.()
  }

  const handleChange = (
    option: OnChangeValue<ScoreSelectOption, boolean>,
  ): void => {
    if (option == null) {
      return
    }
    const { value, maxScore = Number.MAX_SAFE_INTEGER } =
      option as ScoreSelectOption
    setValue(value)
    setRange((range) => {
      const newRange = { ...range }
      if (range.max != null && range.max > maxScore) {
        newRange.max = maxScore
      }
      if (range.min != null && range.min > maxScore) {
        newRange.min = maxScore
      }
      return newRange
    })
  }

  return (
    <Box>
      <FilterCriteriaSelect
        {...selectProps}
        onChange={handleChange}
        autoFocus={true}
        components={{ Option }}
      />
      <Collapse in={hasValue}>
        <>
          <Divider />
          <Box px={3} py={1}>
            <Stack spacing={4} direction="row">
              <ScoreRangeInput
                range={range}
                onChange={setRange}
                maxScore={selectProps.value?.maxScore}
              />
              <Flex flex={1} justify="flex-end">
                <Button primary onClick={() => applyValue()}>
                  <Trans>OK</Trans>
                </Button>
              </Flex>
            </Stack>
          </Box>
        </>
      </Collapse>
    </Box>
  )
}

enum Operator {
  LESS_THAN = '<',
  MORE_THAN = '>',
  RANGE = '-',
  EXACT = '=',
}

const ScoreRangeInput: React.FC<{
  range: ScoreRange
  onChange: React.Dispatch<React.SetStateAction<ScoreRange>>
  maxScore?: number
}> = ({ range, onChange, maxScore = 10 }) => {
  const midScore = Math.max(1, Math.floor(maxScore / 2))
  const [operator, setOperator] = React.useState(() => {
    const { min, max } = range
    if (min != null && min === max) {
      return Operator.EXACT
    }
    if (min == null && max == null) {
      return Operator.MORE_THAN
    }
    if (min != null && max == null) {
      return Operator.MORE_THAN
    }
    if (min == null && max != null) {
      return Operator.LESS_THAN
    }
    return Operator.RANGE
  })

  const operatorOptions = [
    {
      value: Operator.MORE_THAN,
      label: t`Minimum`,
    },
    {
      value: Operator.LESS_THAN,
      label: t`Maximum`,
    },
    {
      value: Operator.RANGE,
      label: t`Between`,
    },
    {
      value: Operator.EXACT,
      label: t`Exactly`,
    },
  ]

  const handleSelectChange = (
    option: OnChangeValue<SelectOption, false>,
  ): void => {
    if (option == null) return
    const newOperator = option.value as Operator

    setOperator(newOperator)

    if (newOperator === Operator.MORE_THAN) {
      onChange((range) => {
        return {
          min: range.min ?? range.max ?? midScore,
          max: undefined,
        }
      })
    }
    if (newOperator === Operator.LESS_THAN) {
      onChange((range) => {
        return {
          min: undefined,
          max: range.max ?? range.min ?? midScore,
        }
      })
    }
    if (newOperator === Operator.RANGE) {
      onChange((range) => {
        if (range.min === range.max) {
          return {
            min: 1,
            max: maxScore,
          }
        }
        return {
          min: range.min ?? 1,
          max: range.max ?? maxScore,
        }
      })
    }
    if (newOperator === Operator.EXACT) {
      onChange((range) => {
        const value = range.min ?? range.max ?? midScore
        return {
          min: value,
          max: value,
        }
      })
    }
  }

  const showMinInput = [
    Operator.MORE_THAN,
    Operator.RANGE,
    Operator.EXACT,
  ].includes(operator)
  const showMaxInput = [Operator.LESS_THAN, Operator.RANGE].includes(operator)
  return (
    <Stack spacing={1} direction="row">
      <Box minW="8em">
        <Select
          value={operatorOptions.find((x) => x.value === operator)}
          options={operatorOptions}
          onChange={handleSelectChange}
          isClearable={false}
          isSearchable={false}
          menuPortalTarget={document.body}
        />
      </Box>
      {showMinInput && (
        <Box w="4.2em">
          <ScoreValueInput
            value={range.min}
            max={
              operator === Operator.RANGE && range.max != null
                ? Math.min(maxScore, range.max)
                : maxScore
            }
            onChange={(value) =>
              onChange((range) => {
                if (operator === Operator.EXACT) {
                  return {
                    min: value,
                    max: value,
                  }
                }
                return { ...range, min: value }
              })
            }
          />
        </Box>
      )}
      {showMaxInput && (
        <Box w="4.2em">
          <ScoreValueInput
            value={range.max}
            min={operator === Operator.RANGE ? range.min : 0}
            max={maxScore}
            onChange={(value) =>
              onChange((range) => ({ ...range, max: value }))
            }
          />
        </Box>
      )}
    </Stack>
  )
}

const ScoreValueInput: React.FC<{
  min?: number
  max?: number
  value?: number
  onChange?: (value: number | undefined) => void
}> = ({ min = 0, max = 10, value, onChange }) => {
  return (
    <NumberInput
      min={min}
      max={max}
      inputMode="numeric"
      onChange={(valueAsString, valueAsNumber) => {
        if (valueAsString === '') {
          onChange?.(undefined)
          return
        }
        if (!Number.isInteger(valueAsNumber)) return
        onChange?.(valueAsNumber)
      }}
      value={value}
      size="sm"
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  )
}
