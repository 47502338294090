import { Stack, UseRadioGroupProps, useRadioGroup } from '@chakra-ui/react'
import React from 'react'

import { RadioButtonProps } from './RadioButton'

export type RadioButtonGroupProps = {
  id?: string
  spacing?: string | number
  colorScheme?: string
  children: React.ReactNode
} & UseRadioGroupProps

export const RadioButtonGroup = React.forwardRef<
  HTMLDivElement,
  RadioButtonGroupProps
>(({ id, spacing = 0, colorScheme, children, ...props }, ref) => {
  const { getRootProps, getRadioProps } = useRadioGroup(props)
  const groupProps = getRootProps()
  return (
    <Stack
      id={id}
      ref={ref}
      direction="row"
      spacing={spacing}
      alignItems="center"
      sx={{
        '.cap-radio-button:first-of-type .cap-radio-button__control': {
          borderTopLeftRadius: 'md',
          borderBottomLeftRadius: 'md',
          borderLeftWidth: 1,
        },
        '.cap-radio-button:last-of-type .cap-radio-button__control': {
          borderTopRightRadius: 'md',
          borderBottomRightRadius: 'md',
        },
        '.cap-radio-button .cap-radio-button__control': {
          borderRadius: 0,
          borderLeftWidth: 0,
        },
        '.cap-radio-button--checked + .cap-radio-button .cap-radio-button__control':
          {
            borderLeftWidth: 1,
          },
      }}
      {...groupProps}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement<RadioButtonProps>(child)) {
          const radioProps = getRadioProps({
            value: child.props.value ?? '',
          })
          return React.cloneElement<RadioButtonProps>(child, {
            radioProps,
            colorScheme: child.props.colorScheme ?? colorScheme,
          })
        }
      })}
    </Stack>
  )
})
