import { ErrorBoundary } from '@capturi/react-utils'
import React from 'react'
import { Route, Routes } from 'react-router'

import routes from './routes'

export { routes }

const CasesPage = React.lazy(() => import('./CaseOverview'))

const Cases: React.FC = () => {
  return (
    <ErrorBoundary>
      <Routes>
        <Route path={'/:uid/*'} element={<CasesPage />} />
        <Route path={'/'} element={<CasesPage />} />
      </Routes>
    </ErrorBoundary>
  )
}

export default Cases
