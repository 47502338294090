import analytics from '@capturi/analytics'
import { BaseTracker } from '@capturi/api-trackers'
import { useCurrentUser } from '@capturi/core'
import { Button, ButtonProps } from '@capturi/ui-components'
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Tooltip,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import {
  MdAdd,
  MdCreateNewFolder,
  MdEdit,
  MdFlare,
  MdMyLocation,
} from 'react-icons/md'
import { useNavigate } from 'react-router'

import routes from '../../routes'

export type CreateTrackerButtonProps = ButtonProps & {
  initialData?: () => Partial<BaseTracker>
  getRedirectPath?: () => string
}

export type EditTrackerButtonProps = ButtonProps & {
  uid: string
  canEditTracker: boolean
  isMasterTarget: boolean
  getRedirectPath?: () => string
}

export const CreateTrackerButton: React.FC<CreateTrackerButtonProps> = ({
  children,
  initialData,
  getRedirectPath,
  onClick: onClickProp,
  ...props
}) => {
  const user = useCurrentUser()
  const canEditTracker = user.isAdminOrTeamLead
  const data = initialData?.()

  const navigate = useNavigate()

  if (!canEditTracker) {
    return null
  }

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    onClickProp?.(e)
    analytics.event('addTracker_open')
    navigate(routes.createTracker(), {
      state: {
        redirectPath:
          getRedirectPath?.() ??
          window.location.href.substring(window.location.origin.length),
        initialData: data,
      },
    })
  }

  if (!children) {
    return (
      <Tooltip label={t`Create tracker`} hasArrow placement="top">
        <IconButton
          aria-label={t`Add tracker`}
          size="sm"
          _hover={{ backgroundColor: 'primary.600' }}
          _focus={{ backgroundColor: 'primary.600' }}
          backgroundColor="primary.500"
          color="white"
          flexShrink={0}
          onClick={handleClick}
          {...props}
        >
          <MdAdd />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <Button
      primary
      size="sm"
      flexShrink={0}
      leftIcon={<MdAdd />}
      onClick={handleClick}
      {...props}
    >
      {children}
    </Button>
  )
}

export const EditTrackerButton: React.FC<EditTrackerButtonProps> = ({
  uid,
  canEditTracker,
  isMasterTarget,
  getRedirectPath,
  children,
  onClick: onClickProp,
  ...props
}) => {
  const navigate = useNavigate()

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    onClickProp?.(e)
    analytics.event('editTracker_open')
    navigate(routes.editTracker(uid), {
      state: {
        redirectPath:
          getRedirectPath?.() ??
          window.location.href.substring(window.location.origin.length),
      },
    })
  }

  const tooltipText = isMasterTarget
    ? t`This tracker can only be edited in the 'Master tracker organisation'`
    : t`You don't have edit rights for this tracker`

  return (
    <Tooltip
      label={tooltipText}
      placement="top"
      hasArrow
      isDisabled={canEditTracker}
    >
      <Box>
        <Button
          secondary
          size="sm"
          leftIcon={isMasterTarget ? <MdFlare /> : <MdEdit />}
          flexShrink={0}
          onClick={handleClick}
          isDisabled={!canEditTracker}
          {...props}
        >
          {children || <Trans>Edit tracker</Trans>}
        </Button>
      </Box>
    </Tooltip>
  )
}

export type CreateTrackerOrFolderButtonProps = ButtonProps & {
  onCreateTracker?: () => void
  onCreateFolder?: () => void
  initialData?: () => Partial<BaseTracker>
  getRedirectPath?: () => string
}

export const CreateTrackerOrFolderButton: React.FC<
  CreateTrackerOrFolderButtonProps
> = ({
  initialData,
  getRedirectPath,
  onCreateTracker,
  onCreateFolder,
  ...props
}) => {
  const user = useCurrentUser()
  const canEditTracker = user.isAdminOrTeamLead
  const data = initialData?.()

  const navigate = useNavigate()

  const handleCreateTrackerClick = React.useCallback(() => {
    onCreateTracker?.()
    analytics.event('addTracker_open')
    navigate(routes.createTracker(), {
      state: {
        redirectPath:
          getRedirectPath?.() ??
          window.location.href.substring(window.location.origin.length),
        initialData: data,
      },
    })
  }, [data, getRedirectPath, navigate, onCreateTracker])

  const handleCreateFolderClick = React.useCallback(() => {
    onCreateFolder?.()
    analytics.event('addTrackerFolder_open')
  }, [onCreateFolder])

  if (!canEditTracker) {
    return null
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label={t`Add tracker or folder`}
        size="sm"
        _hover={{ backgroundColor: 'primary.600' }}
        _focus={{ backgroundColor: 'primary.600' }}
        backgroundColor="primary.500"
        color="white"
        flexShrink={0}
        icon={<MdAdd />}
        {...props}
      />
      <Portal>
        <MenuList>
          <MenuItem icon={<MdMyLocation />} onClick={handleCreateTrackerClick}>
            <Trans>Add new tracker</Trans>
          </MenuItem>
          <MenuItem
            icon={<MdCreateNewFolder />}
            onClick={handleCreateFolderClick}
          >
            <Trans>Add new tracker folder</Trans>
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}
