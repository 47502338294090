import { AuthProvider } from '@capturi/auth'
import { FilterPeriodProvider } from '@capturi/filters'
import request from '@capturi/request'
import { PageLoader } from '@capturi/ui-components'
import { ModalRenderer } from '@capturi/use-modal'
import { ShortcutProvider } from '@shopify/react-shortcuts'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { withTracker } from 'analytics'
import App from 'components/App'
import { CapturiThemeProvider } from 'components/CapturiThemeProvider'
import { AuthManager } from 'features/auth'
import { withPeriodSyncToSearchParams } from 'features/filters/filter-period-enhancers'
import { NotificationsProvider } from 'features/notifications'
import { LanguageProvider } from 'i18n'
import React, { PropsWithChildren, StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import CustomBrowserRouter from 'router/CustomBrowserRouter'
import { SWRConfig, SWRConfiguration } from 'swr'

// If true, render only app main contents, i.e. no nav-sidebar or header
const usp = new URLSearchParams(window.location.search)
const noAppChrome = usp.get('no_chrome') != null
const hideIntercom = usp.get('hide_intercom') != null

const swrSettings: SWRConfiguration = {
  fetcher: request.get,
  shouldRetryOnError: true,
  suspense: true,
}

// react-query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey }) => {
        return await request.get(queryKey[0] as string)
      },
    },
  },
})

const AppWithTracking = withTracker(App, {
  intercom: {
    config: {
      hide_default_launcher: hideIntercom,
    },
  },
  posthog: { config: { autocapture: false, debug: false } },
})

const composeProviders = (
  providers: React.ComponentType<PropsWithChildren>[],
  children: React.ReactElement,
): React.ReactElement => {
  return providers.reduceRight<React.ReactElement>((acc, Provider) => {
    // biome-ignore lint/correctness/useJsxKeyInIterable: øhhm?
    return <Provider>{acc}</Provider>
  }, children)
}

const container = document.getElementById('app-root')
// biome-ignore lint/style/noNonNullAssertion: <explanation>
const root = createRoot(container!)
root.render(
  <StrictMode>
    <CapturiThemeProvider>
      <SWRConfig value={swrSettings}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <LanguageProvider>
            <ShortcutProvider>
              <CustomBrowserRouter>
                <Suspense fallback={<PageLoader />}>
                  {composeProviders(
                    [
                      NotificationsProvider,
                      AuthProvider,
                      AuthManager,
                      withPeriodSyncToSearchParams(FilterPeriodProvider),
                    ],
                    <>
                      <AppWithTracking noChrome={noAppChrome} />
                      <ModalRenderer />
                    </>,
                  )}
                </Suspense>
              </CustomBrowserRouter>
            </ShortcutProvider>
          </LanguageProvider>
        </QueryClientProvider>
      </SWRConfig>
    </CapturiThemeProvider>
  </StrictMode>,
)
