import { useCurrentUser } from '@capturi/core'
import {
  Channel,
  FilterPeriodSelectContainer,
  SingleSegmentContainer,
  useFilterDefinitions,
  useSegmentStatesContext,
} from '@capturi/filters'
import { DefaultFallbackComponent, usePageTitle } from '@capturi/react-utils'
import { PageHeading } from '@capturi/ui-components'
import { Box, Flex, HStack, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { FC } from 'react'

import SessionFailedProcessing from 'components/KeyTopics/FailedStates/SessionFailedProcessing'
import SessionNotFound from 'components/KeyTopics/FailedStates/SessionNotFound'
import { KeyTopicsSessionSelector } from 'pages/HomePage/types'
import { useKeyTopics } from '../../hooks/useKeyTopics'
import {
  KeyTopicsContentSkeleton,
  KeyTopicsReadyContent,
} from './components/KeyTopicsContent'
import { KeyTopicsReadyResponse, KeyTopicsResponse } from './hooks/types'

type KeyTopicsSessionProps = {
  channel: Channel
  includeRepeatCallSettings: boolean
  select: (
    data: KeyTopicsResponse,
  ) => KeyTopicsSessionSelector<Omit<KeyTopicsReadyResponse, 'status'>>
}

const useKeyTopicsSelect = (
  data: KeyTopicsResponse,
): KeyTopicsSessionSelector<Omit<KeyTopicsReadyResponse, 'status'>> => {
  if (data === null) return { status: 'gone' } // handle 204 No Content response
  if (data.status === 'Failed') return { status: 'failed' }
  if (data.status === 'Processing' || data.status === 'Pending')
    return { status: 'loading' }

  return { ...data, status: 'ready' }
}

const KeyTopicsSession: FC<KeyTopicsSessionProps> = (props) => {
  const {
    data,
    isLoading,
    error,
    refetch: startNewSession,
  } = useKeyTopics(props)

  if (error) {
    return <DefaultFallbackComponent error={error} />
  }
  if (data?.status === 'failed')
    return <SessionFailedProcessing startNewSession={startNewSession} />
  if (data?.status === 'gone') {
    return <SessionNotFound startNewSession={startNewSession} />
  }

  if (isLoading || !data || data.status === 'loading') {
    return <KeyTopicsContentSkeleton />
  }

  return <KeyTopicsReadyContent keyTopicsReadyResponse={data} />
}

export const KeyTopicsPage: FC = () => {
  usePageTitle(t`Key topics`)
  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)
  const { states } = useSegmentStatesContext()
  const channel = states[0].channel

  return (
    <Flex flexDir="column">
      <Box mb={4} flex="0 1 auto">
        <Flex align="center" justify="space-between" mb={8}>
          <Box>
            <PageHeading>
              <Trans>Key topics</Trans>
            </PageHeading>
            <Text color="textMuted">
              <Trans>Shows current topics in your conversations</Trans>
            </Text>
          </Box>
          <HStack>
            <FilterPeriodSelectContainer />
          </HStack>
        </Flex>
        <SingleSegmentContainer filterDefinitions={filterDefinitions} />
      </Box>

      <KeyTopicsSession
        channel={channel}
        includeRepeatCallSettings={false}
        select={useKeyTopicsSelect}
      />
    </Flex>
  )
}
