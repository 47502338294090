import analytics from '@capturi/analytics'
import { Box, Collapse, Flex, Heading, Icon } from '@chakra-ui/react'
import React, { ReactElement, useMemo } from 'react'
import { IconType } from 'react-icons'
import { MdSubdirectoryArrowRight } from 'react-icons/md'
import { useLocation } from 'react-router'

import Link from '../../../Link'
import { SidebarGroup } from '../components/SidebarGroup'
import SidebarGroupLink from '../components/SidebarGroupLink'

type Props = {
  basePaths: string[]
  groupName: ReactElement
  icon: IconType
  show?: boolean
  subRoutes: {
    key: string
    name: ReactElement
    url: string
    show: boolean
    hasNewBadge?: boolean
    onClick: () => void
  }[]
}

const MENU_ITEM_HEIGHT = 32

const NavigationGroup: React.FC<Props> = ({
  basePaths,
  show = true,
  subRoutes,
  groupName,
  icon,
}) => {
  const location = useLocation()

  const filteredSubRoutes = useMemo(
    () => subRoutes.filter((s) => s.show),
    [subRoutes],
  )

  if (filteredSubRoutes.length === 0) return null
  const [, currentBasePath, currentSubPath] = location.pathname.split('/')

  const currentActiveIndex = filteredSubRoutes.findIndex(
    (r) => r.key === currentSubPath,
  )
  let currHeight = (currentActiveIndex + 1) * MENU_ITEM_HEIGHT - 15

  if (currentActiveIndex === -1 && currentBasePath === 'conversations')
    currHeight = 15

  return (
    <SidebarGroup>
      <Link
        hidden={!show}
        to={filteredSubRoutes[0].url}
        _hover={{
          bg: 'blackAlpha.100',
        }}
        _focus={{
          bg: 'none',
        }}
        pos="relative"
        borderRadius={4}
        transition="all 0.3s ease-out"
        outline="none !important"
        display="flex"
        flexWrap="nowrap"
        height="8"
        alignItems="center"
        px={2}
        mr={4}
        ml={2}
        opacity="0.8"
        onClick={() => {
          analytics.event('main-menu__nav-item-clicked', {
            href: filteredSubRoutes[0].url,
            groupLink: true,
          })
        }}
        color="gray.800"
      >
        <Icon as={icon} boxSize="18px" mr={2} color="gray.800" />
        <Heading
          as="h4"
          size="md"
          letterSpacing="0.06em"
          mt="1"
          display="flex"
          alignItems="center"
          onClick={() => {}}
          cursor="pointer"
          position="relative"
        >
          {groupName}
        </Heading>
      </Link>
      <Collapse
        in={basePaths.includes(currentBasePath)}
        animateOpacity
        transition={{ enter: { duration: 0.5 }, exit: { duration: 0.5 } }}
      >
        <Flex>
          <Box pl="2" pr="4" w="100%" color="gray.800">
            {filteredSubRoutes.map(
              ({ key, name, url, show, hasNewBadge, onClick }) => {
                return (
                  <SidebarGroupLink
                    key={key}
                    id={key}
                    name={name}
                    show={show}
                    hasNewBadge={hasNewBadge}
                    to={url}
                    onClick={onClick}
                  />
                )
              },
            )}
          </Box>
          <Box mt="-4px">
            <Box
              backgroundColor="gray.500"
              w="1.7px"
              height={`${currHeight}px`}
              transition="height 0.2s ease-in-out"
              position="absolute"
              left="24px"
            >
              <Icon
                position="absolute"
                bottom="-10px"
                color="gray.500"
                left="-3.3px"
                boxSize={5}
                as={MdSubdirectoryArrowRight}
              />
            </Box>
          </Box>
        </Flex>
      </Collapse>
    </SidebarGroup>
  )
}

export default NavigationGroup
