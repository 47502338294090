import analytics from '@capturi/analytics'
import { Badge, Box, Heading } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { ReactElement } from 'react'
import { useMatch, useResolvedPath } from 'react-router'

import Link from '../../../Link'

export type SidebarGroupLinkProps = {
  id: string
  name: ReactElement | string
  to: string
  icon?: React.ComponentType
  show: boolean
  hasNewBadge?: boolean
  onClick: () => void
}

const SidebarGroupLink: React.FC<SidebarGroupLinkProps> = ({
  id,
  name,
  to,
  show,
  hasNewBadge = false,
  onClick,
  ...restProps
}) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({
    path: resolved.pathname,
    end: false,
  })

  const isActive = match != null

  if (!show) return null

  return (
    <Link
      display="flex"
      w="100%"
      alignItems="center"
      to={to}
      variant="unstyled"
      role="menuitem"
      h={8}
      lineHeight={8}
      p={2}
      pl="34px"
      pos="relative"
      borderRadius={4}
      transition="all 0.3s ease-out"
      outline="none !important"
      bg={isActive ? 'gray.300' : 'inherit'}
      opacity={isActive ? '1' : '0.8'}
      _hover={{
        bg: isActive ? undefined : 'blackAlpha.100',
      }}
      _focus={{
        bg: isActive ? undefined : 'blackAlpha.100',
      }}
      letterSpacing="0.03em"
      onClick={() => {
        onClick()
        analytics.event('main-menu__nav-item-clicked', {
          href: to,
        })
      }}
      {...restProps}
    >
      <Heading id={id} size="md" fontWeight="normal">
        {name}
      </Heading>
      {hasNewBadge && (
        <Badge colorScheme="primary" lineHeight="1.2" ml={1}>
          <Trans>New</Trans>
        </Badge>
      )}
      <Box
        position="absolute"
        right="19px"
        top="calc(50%-3px)"
        transform="translateX(50%)"
        w="6px"
        h="6px"
        opacity={isActive ? '1' : '0'}
        background="workspace.sidebar.darkbg"
        borderRadius="999px"
        transition="opacity 0.5s ease-out;"
      />
    </Link>
  )
}

export default SidebarGroupLink
