import analytics from '@capturi/analytics'
import { FC } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router'
import { useEffectOnce } from 'react-use'

const RedirectToWithQuery: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  analytics.event('navigate_to_home_from_pdf_email')
  useEffectOnce(() => {
    navigate(`/home${location.search}`)
  })
  return null
}

const Links: FC = () => {
  return (
    <Routes>
      <Route path={'pdf-email'} element={<RedirectToWithQuery />} />
    </Routes>
  )
}

export default Links
