import { TimeResolution, isTimeResolution } from '@capturi/core'
import { differenceInDays, differenceInHours } from 'date-fns'
import { useEffect, useRef, useState } from 'react'

import { useFilterPeriodContext } from '@capturi/filters'
import constate from 'constate'
import { useSearchParams } from 'react-router'

const getResolution = (
  from: Date | number,
  to: Date | number,
): TimeResolution => {
  if (differenceInHours(to, from) < 48) return 'Hours'
  if (differenceInDays(to, from) < 40) return 'Days'
  if (differenceInDays(to, from) < 90) return 'Weeks'
  return 'Months'
}

const useTimeResolution = (): {
  resolution: TimeResolution
  setResolution: (resolution: TimeResolution) => void
} => {
  const { period } = useFilterPeriodContext()
  const from = period.from.getTime()
  const to = period.to.getTime()

  const [searchParams] = useSearchParams()
  const [resolution, setResolution] = useState<TimeResolution>(() => {
    const resolution = searchParams.get('resolution')
    if (isTimeResolution(resolution)) return resolution
    return getResolution(from, to)
  })

  const prevPeriodRef = useRef({ from, to })
  // biome-ignore lint/correctness/useExhaustiveDependencies: legacy
  useEffect(() => {
    if (
      from === prevPeriodRef.current.from &&
      to === prevPeriodRef.current.to
    ) {
      return
    }

    setResolution(getResolution(from, to))
    prevPeriodRef.current = { from, to }
  }, [from, period, to])

  return { resolution, setResolution }
}

const [TimeResolutionProvider, useTimeResolutionContext] =
  constate(useTimeResolution)

export { TimeResolutionProvider, useTimeResolutionContext }
