import { User } from '@capturi/stores'
import {
  Box,
  Divider,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  chakra,
  useOutsideClick,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { Fragment, useMemo, useRef, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { MdClose } from 'react-icons/md'

type Props = {
  users: User[]
  createdByUserUid: string | undefined
  setNewOwner: (uid: string | undefined) => void
}

const SubscriptionOwnershipTransfer: React.FC<Props> = ({
  users,
  createdByUserUid,
  setNewOwner,
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedAdmin, setSelectedAdmin] = useState({ name: '', uid: '' })
  const [toggleDropdown, setToggleDropdown] = useState(false)
  const menuRef = useRef(null)

  const memoizedUsers = useMemo(() => {
    return users.filter((a) => {
      return (
        a.role !== 'user' &&
        a.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
      )
    })
  }, [searchQuery, users])

  const handleOutsideClick = (): void => {
    setToggleDropdown(false)
  }

  useOutsideClick({
    ref: menuRef,
    handler: handleOutsideClick,
  })

  return (
    <>
      <FormLabel mb={0}>
        <Trans>New owner</Trans>
      </FormLabel>
      <Flex
        width="350px"
        border="1px solid"
        borderColor="gray.200"
        p={2}
        borderRadius="6px"
        position="relative"
        align="center"
        ref={menuRef}
      >
        <Flex
          justify="space-between"
          align="center"
          w="100%"
          onClick={() => setToggleDropdown(!toggleDropdown)}
        >
          <chakra.span>{selectedAdmin.name}</chakra.span>
          <FiChevronDown fontSize="18px" />
        </Flex>
        {selectedAdmin.name && (
          <chakra.span ml={1}>
            <MdClose
              cursor="pointer"
              fontSize="16px"
              onClick={() => {
                setSearchQuery('')
                setSelectedAdmin({ name: '', uid: '' })
                setNewOwner(createdByUserUid)
              }}
            />
          </chakra.span>
        )}
        {toggleDropdown && (
          <Box
            position="absolute"
            top="40px"
            w="100%"
            left="0"
            maxH="330px"
            overflowY="auto"
            backgroundColor="white"
            border="1px solid"
            borderRadius="6px"
            borderColor="gray.200"
          >
            <Flex borderBottom="1px solid" borderColor="gray.200">
              <InputGroup size="md" m={2}>
                <Input
                  autoFocus={true}
                  placeholder={t`Search administrators`}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                {searchQuery.length > 0 && (
                  <InputRightElement cursor="pointer">
                    <MdClose
                      fontSize="14px"
                      onClick={() => setSearchQuery('')}
                    />
                  </InputRightElement>
                )}
              </InputGroup>
            </Flex>
            {memoizedUsers.map((user) => (
              <Fragment key={user.uid}>
                <Box
                  onClick={() => {
                    setSearchQuery('')
                    setSelectedAdmin({ name: user.name, uid: user.uid })
                    setNewOwner(user.uid)
                    setToggleDropdown(!toggleDropdown)
                  }}
                  padding={4}
                  cursor="pointer"
                  _hover={{ backgroundColor: 'gray.100' }}
                >
                  {user.name}
                </Box>
                <Divider />
              </Fragment>
            ))}
          </Box>
        )}
      </Flex>
    </>
  )
}

export default SubscriptionOwnershipTransfer
