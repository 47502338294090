import analytics from '@capturi/analytics'
import {
  Flex,
  HStack,
  Icon,
  InputGroup,
  InputRightElement,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  Tooltip,
  chakra,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React, { useState, useRef } from 'react'
import { MdClose, MdSearch } from 'react-icons/md'

interface SearchChipsInputBaseProps {
  value: string[]
  onChange: (value: string[]) => void
  placeholder?: string
  isLoading?: boolean
  onSubmit?: (values: string[]) => void
}

export const SearchChipsInputBase: React.FC<SearchChipsInputBaseProps> = ({
  value,
  onChange,
  placeholder = t`Search by words in conversations...`,
  isLoading = false,
  onSubmit,
}) => {
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setInputValue(event.target.value)
  }

  const handleSubmit = (): void => {
    const trimmedValue = inputValue.trim()
    if (trimmedValue) {
      addChips(trimmedValue)
      setInputValue('')
      analytics.event('Search-AddPhrase', {
        phrase: trimmedValue,
        totalLength: value.length + 1,
      })
      onSubmit?.(value)
    }
  }

  const handleInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSubmit()
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
    event.preventDefault()
    const pasteData = event.clipboardData.getData('Text')
    addChips(pasteData)
  }

  const addChips = (chips: string): void => {
    const newChips = chips
      .split(',')
      .map((chip) => chip.trim())
      .filter(Boolean)
      .filter((chip) => !value.includes(chip))
    if (newChips.length > 0) {
      onChange([...value, ...newChips])
    }
  }

  const removeChip = (index: number): void => {
    const newValues = [...value]
    analytics.event('Search-RemovePhrase', {
      phrase: newValues[index],
      totalLength: newValues.length - 1,
    })
    newValues.splice(index, 1)
    onChange(newValues)
  }

  const handleClearAll = (): void => {
    onChange([])
    setInputValue('')
    inputRef.current?.focus()
    analytics.event('Search-ClearAll')
  }

  return (
    <InputGroup id="inputgroup" maxW="100%">
      <HStack flexGrow={1} maxW="100%">
        <Flex
          minH="30px"
          flexWrap="wrap"
          maxH="4.6rem" // Allows the component to grow up to 3 lines
          overflowY="auto"
          py="5px"
          px={2}
          gap={1.5}
          borderWidth={1}
          borderColor="gray.300"
          borderRadius="2xl"
          onClick={() => inputRef.current?.focus()}
          pr={value.length > 0 ? 16 : 10}
          boxShadow="inputOutline"
          w="100%"
          transition="all 0.2s ease-in-out"
          transitionProperty="border-color, box-shadow, background-color"
          _focusWithin={{
            borderColor: 'primary.500',
            shadow: 'extended',
          }}
          sx={{
            '&::-webkit-scrollbar': {
              width: '0px',
            },
            '&': {
              scrollbarWidth: 'none',
            },
          }}
        >
          {value.map((chip, index) => (
            <Tag
              key={`${chip}-${index}`}
              size="xs"
              borderRadius="full"
              m={0}
              px={2}
              variant="subtle"
              maxW="100%"
              flexShrink={0}
            >
              <Tooltip
                label={chip}
                placement="bottom"
                openDelay={1000}
                hasArrow
              >
                <TagLabel fontSize="xs" fontWeight="normal">
                  {chip}
                </TagLabel>
              </Tooltip>
              <TagCloseButton ml={1} h={4} onClick={() => removeChip(index)} />
            </Tag>
          ))}
          <chakra.input
            ref={inputRef}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            onPaste={handlePaste}
            placeholder={value.length > 0 ? undefined : placeholder}
            fontSize="sm"
            border="none"
            outline="none"
            flexBasis={8}
            flexShrink={1}
            flexGrow={1}
            minW={8}
            w="auto"
            mx={1}
          />
        </Flex>
        <InputRightElement
          pointerEvents="auto"
          mr={5}
          h="100%"
          alignItems="center"
          justifyContent="flex-end"
          w="auto"
        >
          {value.length > 0 && (
            <Icon
              as={MdClose}
              aria-label={t`Clear all`}
              onClick={handleClearAll}
              boxSize={5}
              cursor="pointer"
              mr={2}
            />
          )}
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <Icon
              as={MdSearch}
              boxSize={5}
              color="gray.500"
              cursor="pointer"
              onClick={handleSubmit}
            />
          )}
        </InputRightElement>
      </HStack>
    </InputGroup>
  )
}
