import { Box, HStack, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import {
  DailyScheduleType,
  MonthlyScheduleType,
  WeeklyScheduleType,
} from 'pages/shared/Subscriptions/types'
import React from 'react'
import { match } from 'ts-pattern'

type Props = {
  schedule: WeeklyScheduleType | DailyScheduleType | MonthlyScheduleType
}

const translateMonthLabel = (dayOfMonth: 'First' | 'Last'): string => {
  return match(dayOfMonth)
    .with('First', () => t`First`)
    .with('Last', () => t`Last`)
    .otherwise(() => t`Last`)
}

const SubscriptionSchedule: React.FC<Props> = ({ schedule }) => {
  const days = schedule.type === 'Weekly' ? schedule.days.join(', ') : undefined
  return (
    <Box fontWeight="medium" fontSize="sm">
      <HStack gap={0}>
        <Text>{days}</Text>
      </HStack>
      {schedule.type === 'Monthly' && (
        <Text>
          <Trans>
            {translateMonthLabel(schedule.dayOfMonth)} day of the month
          </Trans>
        </Text>
      )}
      {schedule.type === 'Daily' && (
        <Text>
          <Trans>day</Trans>
        </Text>
      )}
    </Box>
  )
}

export default SubscriptionSchedule
